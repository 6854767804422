import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedecinService {

  private apisearchbyName = `${API_CONFIG.apiPathBase}/medecins/searchMedecinbyFisrtNameOrLastName`; // Mettez l'URL de votre API ici

  constructor(private http: HttpClient) { }


  searchMedecinByFirstNameOrLastName(nom: string, page: number, limit: number): Observable<any> {
    return this.http.get<any>(`${this.apisearchbyName}`, {
      params: {
        nom: nom,
        page: page.toString(),
        limit: limit.toString()
      }
    });
  }

  getAllMedecins() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin), { headers: headers })

  }
  getAllOtherMedecins(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/otherMedecins/' + id, { headers: headers })

  }
  getMedecinCloser(specialite, latitude, longitude) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/' + latitude + '/' + longitude + '/' + specialite, { headers: headers })

  }
  getMedecinsBySpecialite(specialite) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/by/specialite/' + specialite, { headers: headers })

  }
  getMedecinsNom(nom) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/by/medecin/nom/' + nom, { headers: headers })

  }
  getMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/' + id, { headers: headers })

  }
  getAllMedecinsSearched(params) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/searched/' + params.nom + '/' + params.prenom + '/' + params.specialite + '/' + params.py, { headers: headers })

  }
  //save medecin
  registerMedecin(newMedecin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin), (newMedecin), { headers: headers })

  }
  editProfil(medecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin), (medecin), { headers: headers })

  }
  registerExperience(experiencePro, medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/' + medecinId + '/experiencesPro', (experiencePro), { headers: headers })

  }

  editExperience(experiencePro, medecinId, experienceId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/' + medecinId + '/' + experienceId, (experiencePro), { headers: headers })

  }
  recoverPassword(medecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/forgot', (medecin), { headers: headers })

  }
  verifytoken(medecin, token) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/reset/' + token, (medecin), { headers: headers })

  }
  verifytokenAuthentification(medecin, token) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/authentificate/' + token, (medecin), { headers: headers })

  }
  // verify if token authentification is valide
  verifytokenAuthentificationValidation(token) {
    var headers = new HttpHeaders();
    return this.http.get(_getURL(API_CONFIG.medecin) + '/verify/token/validation/authentification/' + token, { headers: headers })

  }
  // veirfy if token  forgot passwordis valide
  verifytokenValidation(token) {
    var headers = new HttpHeaders();
    return this.http.get(_getURL(API_CONFIG.medecin) + '/verify/token/validation/' + token, { headers: headers })

  }
  verifyPassword(id, medecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/verifiedPassword/' + id, (medecin), { headers: headers })


  }

  verifyNumInscription(admin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/verifyNumInscription', (admin), { headers: headers })

  }
  getAllMedecinsScraped(params) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/' + params.nom + '/' + params.prenom + '/' + params.specialite + '/' + params.py, { headers: headers })

  }

  getAllpatientsPDFExcel(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/all/patients/pdf/excel/' + id, { headers: headers })

  }
  editExpeerienceProMedecin(medecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/edit/experience/pro/medecin/' + medecin._id, (medecin), { headers: headers })

  }
  getMedecinAccepted() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/accepted/medecins', { headers: headers })


  }
  getMedecinScaped() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped), { headers: headers })

  }
  registerMedecinIntegre(medecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecinScraped) + '/admin/newMedecin/add', (medecin), { headers: headers })

  }
  saveAllMedecinXLS(data) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecinScraped) + '/save/all/medecins', (data), { headers: headers })

  }
  getMedecinScapedBypage(page, size) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/page/' + page + '/size/' + size, { headers: headers })

  }
  getMedecinScapedcount() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/list/count/', { headers: headers })

  }
  getMedecinScapedById(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/' + id, { headers: headers })

  }
  getMedecinsWithSelectedMedecin(nom, idMedecinSearch) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/selectedMedecin/medecins/' + nom + '/' + idMedecinSearch, { headers: headers })

  }
  getMedecinsByNameAndSpecialite(specialite, nom) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/not/selectedMedecin/medecins/' + nom + '/' + specialite, { headers: headers })

  }
  getMedecinsWithSelectedMedecinAndSpecualite(id, specialte) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/wwith/selectedMedecin/medecins/' + id + '/with/specialite/' + specialte, { headers: headers })

  }
  getMedecinsWithSelectedVilleAndSpecualite(nom, specialte, ville) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/with/notSelectedMedecin/' + nom + '/with/specialite/' + specialte + '/with/ville/' + ville, { headers: headers })

  }
  getMedecinsWithSelectedVilleAndSpecualiteAndSelectedMedecin(idMedecinSearch, specialite, ville) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/with/selectedMedecin/' + idMedecinSearch + '/with/specialite/' + specialite + '/with/ville/' + ville + '/all', { headers: headers })

  }
  getMedecinsWithSelectedVilleAndNom(nom, ville) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/with/not/selectedMedecin/by/name/' + nom + '/with/selected/ville/' + ville, { headers: headers })

  }
  getMedecinsByVille(ville) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/ville/' + ville, { headers: headers })

  }
  getNearestMedecinsGeneraliste(rechercheWithPosition) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/nearest/doctor/' + rechercheWithPosition, { headers: headers })

  }
  getNearestMedecinsWithNom(rechercheWithPosition, nom) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/nearest/doctor/' + rechercheWithPosition + '/nom/' + nom, { headers: headers })

  }
  getNearestMedecinsWithNomAndSpec(rechercheWithPosition, nom, specialite) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });


    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/nearest/doctor/' + rechercheWithPosition + '/nom/' + nom + '/specialite/' + specialite, { headers: headers })

  }
  getNearestMedecinsWithNomAndSpecSelectedMedecin(rechercheWithPosition, nom, specialite, idMedecinSearch) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinScraped) + '/nearest/doctor/' + rechercheWithPosition + '/medecin/' + idMedecinSearch + '/specialite/' + specialite + '/last', { headers: headers })

  }
  searchMedecin(value) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecin) + '/get/by/all/info/' + value, { headers: headers })

  }

  searchMedecinScrapped(name?: string, specialite?: string, ville?: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });

    let params = new HttpParams();
    if (name) {
      params = params.append('Nom', name);
    }

    if (specialite) {
      params = params.append('Specialite', specialite);
    }

    if (ville) {
      params = params.append('Ville', ville);
    }

    return this.http.get<any>(_getURL(API_CONFIG.medecinScraped) + '/searchMedecin/multiple', { params, headers });
  }
  // Matching 
  // platforme medecin
  searchPatientInDb(idPatient, dateNaissPatient): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.medecinPatient) + `/searchPatientInDb/${idPatient}/${dateNaissPatient}`, { headers: headers });
  }

  // platfome medecin

  matchingPatientMedecin(Patient: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecinPatient) + '/demandeMatching', Patient, { headers: headers });
  }
  affectationPatientMedecin(Patient: any,): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecinPatient) + '/affectationMatching', Patient, { headers: headers });
  }
  // !----------fonctinalité pour Medecin : id est statique
  updatePhoto(id, data) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/add/new/image/to/medecin/' + id, data, { headers: headers })
  }
  updateLogo(data) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/add/new/image/to/medecin/logo/cabinet', data, { headers: headers })
  }
  updatePasswordNewFunction(medecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/edit/nouveau/mot/de/passe/' + medecin._id, (medecin), { headers: headers })
  }
}
