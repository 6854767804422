<div class="select-container" [ngStyle]="selectContainer">
  <h3 *ngIf="label">{{ label }}</h3>
  <ng-select
    [items]="items"
    [(ngModel)]="selectedItems"
    (ngModelChange)="selectedItemsChange.emit($event)"
    [multiple]="multiple"
    [searchable]="true"
    [ngStyle]="ngSelectStyle"
    [placeholder]="placeholder">

    <!-- Custom template to hide selected items inside the input -->
    <ng-template ng-label-tmp let-item="item" *ngIf="multiple">
      <!-- Empty template to hide selected items -->
    </ng-template>
  </ng-select>

  <!-- Display selected items externally -->
  <div class="selected-items" *ngIf="selectedItems?.length" [ngStyle]="selectedItemsStyle">
    <div class="selected-item" *ngFor="let item of selectedItems; let i = index">
      {{ item }}
      <button (click)="removeSelectedItem(i)">&times;</button>
    </div>
  </div>

  <!-- Display single selected item when multiple is false -->
  <div class="selected-item-single" *ngIf="!multiple && selectedItems?.length">
    {{ selectedItems[0] }}
    <button (click)="clearAll()">&times;</button>
  </div>
</div>