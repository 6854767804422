// fullscreen.service.ts
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {
  
  fullscreenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  private fullscreenElement: any = null;

  constructor() {
    // Initialize fullscreenElement based on browser support
    document.addEventListener('fullscreenchange', () => this.emitFullscreenChange());
    document.addEventListener('webkitfullscreenchange', () => this.emitFullscreenChange());
    document.addEventListener('mozfullscreenchange', () => this.emitFullscreenChange());
    document.addEventListener('MSFullscreenChange', () => this.emitFullscreenChange());
  }

  isFullscreen(): boolean {
    return !!(
      document.fullscreenElement || 
      document['webkitFullscreenElement'] || 
      document['mozFullScreenElement'] || 
      document['msFullscreenElement']);
  }

  toggleFullscreen(): void {
    if (!this.isFullscreen()) {
      this.enterFullscreen();
    } else {
      this.exitFullscreen();
    }
  }

  private enterFullscreen(): void {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element['webkitRequestFullscreen']) {
      element['webkitRequestFullscreen']();
    } else if (element['mozRequestFullScreen']) {
      element['mozRequestFullScreen']();
    } else if (element['msRequestFullscreen']) {
      element['msRequestFullscreen']();
    }
  }

  private exitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  private emitFullscreenChange(): void {
    this.fullscreenChange.emit(this.isFullscreen());
  }
}
