import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { _getURL, API_CONFIG } from 'config/API_CONFIG';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private apiUrl = _getURL(API_CONFIG.event)

  constructor(private http: HttpClient) {
  }

  getAllEvents() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl, { headers: headers })
  }
  getAllEventsByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/' + id, { headers: headers })
  }
  // get all events libre of medecin
  getAllEventsLibresByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/eventslibres/' + id, { headers: headers })
  }
  deleteEvent(eventID) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.delete(this.apiUrl + '/' + eventID, { headers: headers })
  }
  registerEvent(newEvent) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl, JSON.stringify(newEvent), { headers: headers })
  }
  registerEventProposition(rdvObject){
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl+'/send/mail', JSON.stringify(rdvObject), { headers: headers })
  }
  editEvent(event) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(this.apiUrl + '/' + event._id, JSON.stringify(event), { headers: headers })
  }

}
