import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-suppression',
  templateUrl: './suppression.component.html',
  styleUrls: ['./suppression.component.css']
})
export class SuppressionComponent implements OnInit {

  constructor(public modal: NgbActiveModal) { }
  name: any
  ngOnInit(): void {
  }

  onReset() {
    this.modal.dismiss();
  }
  confirm() {
    this.modal.close('confirm');
  }

}
