<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light">
    <section class="error-page section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-12">
                    <!-- Error Inner -->
                    <div class="error-inner">
                        <h1>404<span>Désolé, page introuvable !</span></h1>
                        <p></p>
                    </div>
                    <!--/ End Error Inner -->
                </div>
            </div>
        </div>
    </section>
</div>

<app-footer></app-footer>