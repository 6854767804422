import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { Patient } from './patient.model';
import { API_CONFIG, _getURL, } from 'config/API_CONFIG';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private apiUrl = _getURL(API_CONFIG.patients)
  constructor(
    private http: HttpClient,
  ) {

  }

  //save patient
  registerPatient(newPatient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.register), (newPatient), { headers: headers })

  }

  getPatient() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients), { headers: headers })

  }
  editProfil(patient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });

    return this.http.put(_getURL(API_CONFIG.patients), patient, { headers: headers }).pipe(
      map(response => {
        // Process the response if needed
        return response;
      }),
      catchError(error => {
        // Handle the error
        console.error('Error updating profile:', error);
        return throwError(error);
      })
    );
  }
  editlistMedecinsPatient(patient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.patients) + '/listMedecinsId', (patient), { headers: headers })

  }
  getPatientByID(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/' + id, { headers: headers })

  }
  getPatientByIDNewFunction(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/new/function/' + id, { headers: headers })

  }
  getAllPatientsByRecherche(params) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/' + params.nom + '/' + params.email + '/' + params.tel, { headers: headers })

  }
  getAllComptePatientsByRecherche(params) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/searched/comptes/' + params.identifiant + '/' + params.dateAnniversaire, { headers: headers })

  }
  verifyEmailPatient(patient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.patients) + '/mobile/verifiedByEmail', (patient), { headers: headers })

  }

  getPatientsByDoctor(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/withoutExamen/' + id, { headers: headers })

  }
  // !----------fonctinalité pour Medecin : id est statique
  updatePhoto(id, data) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
    });
    return this.http.put(_getURL(API_CONFIG.patient) + '/addImagetoPatient/' + id, data, { headers: headers })
  }
  // get last twenty medecins by medecin
  getLastTwentyPatient() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/get/last/twenty/patient', { headers: headers })
  }

}
