import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { HistoriqueConsultationComponent } from '../../popups/historique-consultation/historique-consultation.component';
@Component({
  selector: 'app-examen-sidebar',
  templateUrl: './examen-sidebar.component.html',
  styleUrls: ['./examen-sidebar.component.css']
})
export class ExamenSidebarComponent implements OnInit {
  
  currentRoute: string;
  isOpen: Boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.urlAfterRedirects;
    });
  }

  isActiveLink(path: string): boolean {
    const basePathInterrogatoire = '/medecin/examen/interrogatoire';
    const currentRoute = this.currentRoute || '';
  
    if (currentRoute.startsWith(basePathInterrogatoire) && path === 'interrogatoire') {
      return true;
    }
  
    // Re add this condition for another component if he will have child components
  
    return false;
  }

  navigateToInterrogatoire(): void {
    this.router.navigate(['/medecin/examen/interrogatoire/antecedents-medicaux']);
  }

  historiqueConsultationModal() {
    const modalRef = this.modalService.open(HistoriqueConsultationComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.consultationHistorique.subscribe(() => {
      console.log("Test modal done")
    })
  }

  toggleOffCanvas(): void {
    this.isOpen = !this.isOpen;
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }
  
}