<div class="modal-header">
  <h5 class="mb-0">Ajouter un rendez-vous</h5>
  <a class="exit" aria-label="Close" (click)="onReset()">
    <i class="fa-regular fa-circle-xmark"></i>
  </a>
</div>
<div class="modal-body">
  <h6 class="choose">Veuillez entrez l'id et le numéro de fiche du Patient</h6>
  <div class="input-container mb-3">
    <div class="input-group inputRechPat">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon3">Mediv-</span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Id de patient"
        [(ngModel)]="idPatient"
        name="idPatient"
        onkeypress="return /[0-9-]/.test(event.key)"
      />
    </div>
    <input
      type="text"
      class="form-control inputRechPat"
      placeholder="N° Fiche"
      [(ngModel)]="numFiche"
      name="numFiche"
      onkeypress="return /[a-zA-Z0-9]/.test(event.key)"
    />
  </div>
  <hr class="split" />
  <h6 class="choose">Veuillez choisir la date du rendez-vous</h6>
  <div class="date-header mt-2">
    <!-- <span class="arrow" (click)="previousMonth()">&lt;</span> -->
    <span class="badge badge-primary">
      {{ calendarDays[0]?.monthName }} - {{ currentYear }}
    </span>
    <!-- <span class="arrow" (click)="nextMonth()">&gt;</span> -->
  </div>
  <div class="calendar mb-3">
    <span class="arrow" (click)="previousWeek()">&lt;</span>
    <div
      *ngFor="let day of calendarDays"
      [class.active]="day.active"
      [class.disabled]="day.isPast || !day.isAvailable"
      class="calendar-day"
      (click)="selectDay(day)"
    >
      <span class="day-name">{{ day.dayName }}</span>
      <span class="day-number">{{ day.dayNumber }}</span>
      <span class="month-name">{{ day.monthName || "Unknown Month" }}</span>
    </div>
    <span class="arrow" (click)="nextWeek()">&gt;</span>
  </div>
  <div *ngIf="selectedDay">
    <hr class="split" />
    <h6 class="choose">Veuillez choisir l'heure du rendez-vous</h6>
    <div class="time-slots mb-3">
      <div
        *ngFor="let slot of timeSlots"
        [class.active]="slot.active"
        class="time-slot"
        (click)="selectTimeSlot(slot)"
      >
        {{ slot.time }}
      </div>
    </div>
  </div>
  <hr class="split" />
  <h6 class="choose">Choisir le motif de consultation</h6>
  <ng-select
    [items]="listMotifs"
    class="form-control input-custom"
    [closeOnSelect]="true"
    multiple="true"
    [(ngModel)]="selectedMotif"
    name="selectedMotif"
  ></ng-select>
  <hr class="split-btns" />
  <div class="buttons">
    <button class="btn btn-secondary mr-2" (click)="onReset()">Annuler</button>
    <button class="btn btn-primary" (click)="addRdv()">Enregister</button>
  </div>
</div>
