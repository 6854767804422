import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-motif-consultation',
  templateUrl: './motif-consultation.component.html',
  styleUrls: ['./motif-consultation.component.css']
})
export class MotifConsultationComponent implements OnInit {
  
  listMotifs: any;
  selectedMotifs: any[] = [];
  newMotif :any

  displayList = true

  constructor(
    public modal: NgbActiveModal,
    public toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.initialisationMotifs();
  }

  initialisationMotifs(): void {
    this.listMotifs = [
      "Douleurs musculaires et articulaires",
      "Fatigue générale",
      "Maux de tête fréquents",
      "Troubles du sommeil",
      "Infections respiratoires (rhume, grippe, bronchite)",
      "Problèmes digestifs (maux de ventre, ballonnements, diarrhée, constipation)",
      "Douleurs abdominales",
      "Fièvre persistante",
      "Toux persistante",
      "Douleurs thoraciques"
    ]
  }

  addMotif(){
    this.displayList = false
  }

  saveMotif(value){
    if(!value || value == ""){
      this.toastService.showError('Une erreur est survenue.');
      return false
    } else {
      this.listMotifs.push(value)
      this.displayList = true
    }
  }

  cancelNotMotif(){
    this.displayList = true
  }

  onReset() {
    this.modal.dismiss();
  
  }
  

}
