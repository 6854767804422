import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/services/event/event.service';
import { RdvsService } from 'src/app/services/rdvs/rdvs.service';

import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-add-rdv',
  templateUrl: './add-rdv.component.html',
  styleUrls: ['./add-rdv.component.css']
})
export class AddRdvComponent implements OnInit {
  @Output() rdvAdded = new EventEmitter<void>();

  medecinId: string | null;
  idPatient: string = '';
  numFiche: string = '';
  selectedMotif: string[] = [];

  currentYear: number;
  currentMonth: number;
  currentWeekIndex: number = 0;

  selectedDay: any;
  selectedTimeSlot: any;

  listMotifs: any;
  calendarDays: any[] = [];
  timeSlots: any[] = [];
  monthNames = [
    "Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"
  ];

  events: any[] = [];
  allFreeEvents: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    private rdvService: RdvsService,
    private toastService: ToastService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    const today = new Date();
    this.currentYear = today.getFullYear();
    this.currentMonth = today.getMonth();

    this.getMedecinId();
    this.fetchAllFreeEvents().then(() => {
      // this.initializeCalendarDays();
      this.initializeMotifs();
    });
  }

  getMedecinId() {
    if (localStorage.getItem("medecin")) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.medecinId = medecinId;
    } else {
      return
    }
  }

  fetchAllFreeEvents() {
    return this.eventService.getAllEventsLibresByMedecin(this.medecinId).toPromise().then(
      (data: any[]) => {
        this.allFreeEvents = data;
        this.initializeCalendarDays();
      },
      error => {
        console.error('Error fetching free events', error);
      }
    );
  }
  

  addRdv() {

    if (!this.idPatient || !this.numFiche || !this.selectedDay || !this.selectedTimeSlot || !this.selectedMotif.length) {
      this.toastService.showError("Merci de remplir tous les champs");
      return;
    }

    const fullIdPatient = `Mediv-${this.idPatient}`;
    const date = this.selectedDay.date;
    const time = this.selectedTimeSlot.time;

     // Format date and time to 'YYYY-MM-DDTHH:mm:ss'
    const start = `${date.getFullYear()}-${this.padZero(date.getMonth() + 1)}-${this.padZero(date.getDate())}T${time}:00`;

    const rdvData = {
      idPatient: fullIdPatient,
      numFiche: this.numFiche,
      start,
      motif: this.selectedMotif,
      medecinId : this.medecinId
    };

    this.rdvService.addRdvByMedecin(rdvData).subscribe(
      (response) => {
        this.toastService.showSuccess("Rendez-vous ajouté avec succès");
        this.rdvAdded.emit();
        this.modal.close();
      },
      (error) => {
        console.error('Error adding RDV:', error);
        this.toastService.showError("Erreur lors de l'ajout du rendez-vous");
      }
    );
  }

  initializeMotifs() {
    this.listMotifs = [
      "Visite médicale régulière pour un bilan de santé général.",
      "Consultation pour des symptômes tels que fièvre, toux, maux de gorge et fatigue.",
      "Évaluation des douleurs ou inconforts abdominaux persistants.",
      "Surveillance et gestion de l'hypertension (pression artérielle élevée).",
      "Consultation pour des difficultés respiratoires, comme l'asthme ou les allergies."
    ];
  }

  initializeCalendarDays() {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // Reset time to midnight in UTC
    const startOfWeek = today.getUTCDate() - today.getUTCDay() + 1;
    const startDate = new Date(today);
    startDate.setUTCDate(startOfWeek);
  
    const days = [];
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setUTCDate(date.getUTCDate() + i);
      const dayName = date.toLocaleString('fr-FR', { weekday: 'short', timeZone: 'UTC' }).replace('.', '');
      const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
      const dayNumber = date.getUTCDate();
      const monthName = this.monthNames[date.getUTCMonth()];
  
      const isAvailable = this.allFreeEvents.some(event => {
        const { date: eventDate } = this.splitDateTime(event.start);
        return eventDate === date.toISOString().split('T')[0];
      });
  
      const isPast = date < new Date(new Date().setHours(0, 0, 0, 0)); // Disable if it's a past date

  
      days.push({ date, dayName: capitalizedDayName, dayNumber, monthName, active: false, isAvailable, isPast });
    }
  
    this.calendarDays = days;
  }
  
  updateCalendarDays() {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // Reset time to midnight in UTC
    const startOfWeek = today.getUTCDate() - today.getUTCDay() + 1 + this.currentWeekIndex * 7;
    const startDate = new Date(today);
    startDate.setUTCDate(startOfWeek);
  
    const days = [];
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setUTCDate(date.getUTCDate() + i);
      const dayName = date.toLocaleString('fr-FR', { weekday: 'short', timeZone: 'UTC' }).replace('.', '');
      const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
      const dayNumber = date.getUTCDate();
      const monthName = this.monthNames[date.getUTCMonth()];
  
      const isAvailable = this.allFreeEvents.some(event => {
        const { date: eventDate } = this.splitDateTime(event.start);
        return eventDate === date.toISOString().split('T')[0];
      });
  
      const isPast = date < new Date(new Date().setUTCHours(0, 0, 0, 0)); // Disable if it's a past date
  
      days.push({ date, dayName: capitalizedDayName, dayNumber, monthName, active: false, isAvailable, isPast });
    }
  
    this.calendarDays = days;
  }
  
  nextWeek() {
    const currentStartDate = new Date(this.calendarDays[0].date);
    const nextWeekStartDate = new Date(currentStartDate);
    nextWeekStartDate.setUTCDate(nextWeekStartDate.getUTCDate() + 7);
  
    if (nextWeekStartDate.getUTCFullYear() !== this.currentYear) {
      this.currentYear = nextWeekStartDate.getUTCFullYear();
    }
    
    this.currentWeekIndex++;
    this.updateCalendarDays();
  }
  
  previousWeek() {
    if (this.currentWeekIndex > 0) {
      const currentStartDate = new Date(this.calendarDays[0].date);
      const previousWeekStartDate = new Date(currentStartDate);
      previousWeekStartDate.setUTCDate(previousWeekStartDate.getUTCDate() - 7);
  
      if (previousWeekStartDate.getUTCFullYear() !== this.currentYear) {
        this.currentYear = previousWeekStartDate.getUTCFullYear();
      }
  
      this.currentWeekIndex--;
      this.updateCalendarDays();
    }
  }
  

  selectDay(selectedDay) {
    if (!selectedDay.isAvailable || selectedDay.isPast) {
      return;
    }
  
    // Clear previous selections and set the new selected day
    this.calendarDays.forEach(day => day.active = false);
    selectedDay.active = true;
  
    // Update time slots for the selected day
    this.updateTimeSlotsForSelectedDay(selectedDay);
  }  
  
  updateTimeSlotsForSelectedDay(selectedDay) {
    if (!selectedDay.isAvailable || selectedDay.isPast) {
      this.timeSlots = []; // Clear time slots if the day is not available or is past
      return;
    }
  
    // Set the selected day
    this.selectedDay = { ...selectedDay, yearNumber: selectedDay.date.getUTCFullYear() };
  
    // Filter events for the selected day
    const filteredEvents = this.allFreeEvents.filter(event => {
      const { date } = this.splitDateTime(event.start);
      return date === this.selectedDay.date.toISOString().split('T')[0];
    });
  
    // Update time slots based on the filtered events
    const slots = filteredEvents.map(event => {
      const { timeSlot } = this.splitDateTime(event.start);
      return { time: timeSlot, active: false, dateTime: new Date(`${event.start}Z`), eventId: event._id };
    });
  
    // Sort time slots by dateTime
    slots.sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());
  
    // Assign sorted time slots
    this.timeSlots = slots;
  }    

  selectTimeSlot(selectedSlot) {
    this.timeSlots.forEach(slot => slot.active = false);
    selectedSlot.active = true;
    this.selectedTimeSlot = { ...selectedSlot };
  }

  splitDateTime(dateTime: string) {
    const [date, timeSlot] = dateTime.split('T');
    return { date, timeSlot: timeSlot.substring(0, 5) };
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  
  onReset() {
    this.modal.dismiss();
  }

}
