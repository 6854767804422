<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light page-content-container">
    <div class="container-fluid examen-container">
        <div class="examen-details">
            <app-examen-details></app-examen-details>
        </div>
        <div class="row examen-sidebar-content">
            <div class="col-12 col-md-3 col-lg-2 examen-sidebar">
                <app-examen-sidebar></app-examen-sidebar>
            </div>
            <div class="col-12 col-md-9 col-lg-10 examen-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
