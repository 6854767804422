<div class="interrogatoire-content">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="card">
        <h3 class="title">Interrogatoire</h3>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>