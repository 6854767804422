import { ConsultationService } from 'src/app/services/consultation/consultation.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MedecinService } from 'src/app/services/medecin/medecin.service';


@Component({
  selector: 'app-popup-search-patient',
  templateUrl: './popup-search-patient.component.html',
  styleUrls: ['./popup-search-patient.component.css']
})
export class PopupSearchPatientComponent implements OnInit {

  patientForm: FormGroup;
  patients: any[]; // Assurez-vous de définir correctement le type de vos patients
  searchedPatient: any = ""; // Initialisez searchedPatient à null par défaut
  searchInitiated: boolean = false;
  affectationPatient: boolean = false;
  validationSection: boolean = false;
  patientToValidate: any = ""
  medecinUsedId: any
  sizeContentListPatients: number;
  searchText: any;
  recentResultspatients: any = []
  nom: any
  prenom: any
  numFiche: any
  age: any
  today: Date = new Date()
  error: any
  selectedPatients = {
    "patient": {
    },
    "patientExist": ""

  }

  constructor(public modal: NgbActiveModal, private fb: FormBuilder, private meddecinService: MedecinService, public iziToast: Ng2IzitoastService, private consultationService: ConsultationService) {
    this.patientForm = this.fb.group({
      patientId: ['', Validators.required],
      patientDOB: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }

  onReset() {
    this.modal.dismiss();
    this.resetSearch();
  }

  isButtonVisible(): boolean {
    return this.patientForm.valid;
  }

  onSearch() {
    this.meddecinService.searchPatientInDb(this.patientForm.value.patientId, this.patientForm.value.patientDOB).subscribe(data => {
      if (data.errors) {
        this.error = data.errors[0]
        this.iziToast.show({
          message: this.error.msg,
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });

      } else if (data.success == false) {
        this.searchedPatient = undefined
      } else {
        this.searchedPatient = data.patient
        this.searchInitiated = true;
      }
    })
  }

  affectPatient() {
    this.affectationPatient = true
    this.getPatients()
  }
  getPatients() {
    this.consultationService.getAllPatientsByMedecin(this.medecinUsedId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        this.patients = this.patients.filter(el => el.createdByDoctor == true)
        this.sizeContentListPatients = this.patients.length
        this.recentResultspatients = this.patients
        if (this.patients.length) {

          this.patients = this.patients.sort(this.triapatients);

          // this.copyListPatients = this.patients
        }
      });
  }
  search() {

    if (this.nom != "" || this.prenom != "" || this.numFiche != "" || this.age != "") {
      this.patients = this.recentResultspatients
      this.patients = this.patients.filter(res => {
        return res.nom.toLocaleLowerCase().match(this.nom.toLocaleLowerCase())
          || res.prenom.toLocaleLowerCase().match(this.prenom.toLocaleLowerCase())
          || res.numFiche.toLocaleLowerCase().match(this.numFiche.toLocaleLowerCase())
          || res.age.toLocaleLowerCase().match(this.age.toLocaleLowerCase())
      })
      // this.recentResultspatients = this.patients
      // this.page=1
    }
    else if (this.nom == "" && this.prenom == "" && this.numFiche == "" && this.age == "") {
      this.patients = this.recentResultspatients
      //this.lengthListDistributers =this.patients.length 
    }
  }
  initSearch() {
    this.affectationPatient = false
    this.onResetForm()
  }
  onResetForm() {
    this.patientForm.reset();
    this.resetSearch();
  }

  resetSearch() {
    this.searchedPatient = null;
    this.searchInitiated = false;
  }

  ajoutMatching() {
    this.meddecinService.matchingPatientMedecin(this.searchedPatient).subscribe(data => {
      if (data.success == false) {
        this.iziToast.show({
          message: "Ce patient est déja exisant!",
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        this.modal.dismiss();
        return false

      } else {
        this.iziToast.show({
          message: 'La demande de validation est envoyé avec succès',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: false,
        });
        this.modal.dismiss();
      }
    }, (error) => {
      console.log(error);
    })
  }
  patientToAffect(patient) {
    if (patient) {
      this.patientToValidate = patient
      this.validationSection = true
    } else {
      this.patientToValidate = undefined
      this.validationSection = false
    }
  }
  ValidteAffectPatient() {
    this.selectedPatients.patient = this.searchedPatient
    this.selectedPatients.patientExist = this.patientToValidate._id

    this.meddecinService.affectationPatientMedecin(this.selectedPatients).subscribe((response) => {
      if (response.success) {
        this.iziToast.show({
          message: 'Affectation faite avec succès',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: false,
        });
        this.modal.dismiss();
      } else {
        this.iziToast.show({
          message: "Ce patient est déja exisant!",
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        this.modal.dismiss();
        return false
      }

    }, (error) => {
      console.log(error);
    })

  }
  triapatients(a, b) {
    if (a.created_at > b.created_at) return -1;
    else if (a.created_at == b.created_at) return 0;
    else return 1;
  }

}
