<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light" style="height: 99%;">
  <section class="mt-3">

    <div id="demo">
      <div class="row">
        <div class="col-9">
          <div class="col-3 float-left">
            <span class="dot" style="background-color: #039be5"></span> <span id="spanColor">Les rendez-vous
              personnels</span>
          </div>
          <div class="col-3 float-left">
            <span class="dot" style="background-color: #23b1a5"></span> <span id="spanColor">Les dates libres</span>
          </div>
          <div class="col-3 float-left">
            <span class="dot" style="background-color: #21726B"></span> <span id="spanColor">Les rendez-vous
              professionnels</span>
          </div>
          <div class="col-3 float-left">
            <span class="dot" style="background-color: #f48a54"></span> <span id="spanColor">Les rendez-vous en
              attente</span>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-9" style="margin-left: 15px;width: 71%;">
          <ng-fullcalendar id="calendar" [options]="calendarOptions" style="color:black"
            (eventClick)="eventClick($event.detail.event)" (dayClick)="dayClick($event.detail)"></ng-fullcalendar>
        </div>
        <div class="col-3">
          <div>
            <h3 class="primary bold-color">Le {{today}}</h3>
            <div class="shadowed" style="padding: 10px;" *ngIf="rdvsToday?.length > 0">
              <div *ngFor="let rd of rdvsToday| paginate: { itemsPerPage: 4, currentPage: p }">
                <h4 class="accent"> {{rd.date| date: 'HH:mm'}}</h4>
                <h4>{{rd.nom_patient}} {{rd.prenom_patient}}</h4>
                <hr>
              </div>
              <div class="row">
                <div class="col col-xs-6">
                  <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event"
                    #api></pagination-controls>
                </div>
              </div>
            </div>
            <div class="shadowed" style="padding: 10px;" *ngIf="rdvsToday?.length === 0">
              <div>
                <h4 class="accent"> Aucun rendez-vous</h4>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br>
  </section>

</div>

<app-footer></app-footer>

<div id="createEventModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content modal-custom">
      <div class="modal-header">
        <h4 class="modal-title">Ajouter un événement</h4>
        <a class="exit" aria-label="Close" (click)="onReset()">
          <i class="fa-regular fa-circle-xmark"></i>
      </a>
      </div>
      <div id="modalBody" class="modal-body">
        <form [formGroup]="form" class="form-horizontal" role="form" style="left: 9%;">
          <div class="col-12">
            <label class="choose" for="Type">Type<span class="required-asterisk">*</span></label>
            <select name="type" (change)="showAndHideTitle()" formControlName="type" class="form-control form-select">
              <option>Personnel</option>
              <option>Professionnel</option>
            </select>
            <control-messages [control]="form.controls.type"></control-messages>
          </div>
          <div class="col-12 form-block" *ngIf="showTitle">
            <label class="choose" for="titre">Titre</label>
            <input type="text" class="form-control" name="title" formControlName="title" id="title">
            <control-messages [control]="form.controls.title"></control-messages>
          </div>
          <div class="dropdown-cont">
            <div class="col-6">
              <label class="choose" for="">De<span class="required-asterisk">*</span></label>
              <select name="timeStart" (change)="controlerTimeFermeture()" formControlName="timeStart" class="form-control form-select">
                <option></option>
                <option *ngFor="let hour of hours">{{hour}}</option>
              </select>
              <control-messages [control]="form.controls.timeStart"></control-messages>
            </div>
            <div class="col-6">
              <label class="choose" for="timeEnd">A<span class="required-asterisk">*</span></label>
              <select name="timeEnd" formControlName="timeEnd" class="form-control form-select">
                <option></option>
                <option *ngFor="let op of optionsTimeFermeture">{{op}}</option>
              </select>
              <control-messages [control]="form.controls.timeEnd"></control-messages>
            </div>
          </div>
          <div class="col-12 form-block" *ngIf="!showTitle">
            <label class="choose duration-label" for="Type">Répétition<span class="required-asterisk">*</span></label>
            <div class="event-durations mb-3">
              <button type="button" class="event-duration" data-duration="15" (click)="updateDuration(15)">15 minutes</button>
              <button type="button" class="event-duration" data-duration="20" (click)="updateDuration(20)">20 minutes</button>
              <button type="button" class="event-duration" data-duration="30" (click)="updateDuration(30)">30 minutes</button>
            </div>
          
            <!-- Hidden select field -->
            <select name="timing" formControlName="timing" style="display: none;">
              <option value="15">15min</option>
              <option value="20">20min</option>
              <option value="30">30min</option>
            </select>
            <control-messages [control]="form.controls.type"></control-messages>
          </div>
          
          <div class="modal-footer">
            <div class="buttons">
              <button class="btn btn-secondary" data-dismiss="modal">Annuler</button>
              <button class="btn btn-primary" [disabled]="!form.valid" (click)="saveEvent()">Enregistrer</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div id="detailsEventModal" class="modal fade" *ngIf="eventClicked">
  <div class="modal-dialog">
    <div class="modal-content" style="width:100%">
      <div class="modal-header">
        <h4 style="color:black;margin: auto;">Détails d'un rendez-vous</h4>
      </div>
      <form [formGroup]="updateform" style="position: relative;left: 9%;">
        <div id="modalBody" class="modal-body" style="position: relative;padding: 0px;">
          <div class="col-12">
            <label for="Type">Type*</label>
            <select name="type" formControlName="type" value="{{eventClicked.type}}" [(ngModel)]="eventClicked.type"
              class="form-control">
              <option>Personnel</option>
              <option>Professionnel</option>
            </select>
            <control-messages [control]="form.controls.type"></control-messages>
          </div>
          <div class="col-12" *ngIf="eventClicked.type == 'Personnel'">
            <label for="">Titre</label>
            <input type="text" class="form-control" formControlName="title" [(ngModel)]="eventClicked.title"
              style="width:80%" name="eventClicked.title" id="title" value="{{eventClicked.title}}">
            <control-messages [control]="updateform.controls.title"></control-messages>
          </div>
          <div class="col-12">
            <label for="">De*</label>
            <input class="form-control" formControlName="start" name="eventClicked.start" style="width:80%"
              value="{{eventClicked.start}}" [(ngModel)]="eventClicked.start" id="start" type="datetime-local"
              required="required" />
            <p *ngIf="showErrorDate" style="color:red">Vérifier les dates</p>
            <control-messages [control]="updateform.controls.start"></control-messages>

          </div>
          <div class="col-12">
            <label for="">À*</label>
            <input class="form-control" formControlName="end" name="end" name="eventClicked.end" style="width:80%"
              value="{{eventClicked.end}}" [(ngModel)]="eventClicked.end" id="end" type="datetime-local"
              required="required" />
            <control-messages [control]="updateform.controls.end"></control-messages>
          </div>
          <div class="modal-footer d-flex justify-content-center" style="border:none">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" style="width: 115px;font-size: 13px;" data-dismiss="modal"
                        aria-hidden="true" (click)="modifierEvent(eventClicked)" [disabled]="!updateform.valid">Enregistrer </button>
                    </td>
                    <td class="tdButt2 ">
                      <div class="dropdown" style="float: inherit;margin-right: 5px;">
                        <button type="button" name="button" style="width: 115px;font-size: 13px;"
                          class="btn-annule dropdown-toggle outils btn btn-danger" data-toggle="dropdown"><br>Supprimer</button>
                        <ul class="dropdown-menu profil-menu">
                          <li style="cursor: pointer;"><a (click)="deleteEvent(eventClicked)" data-dismiss="modal"
                              aria-hidden="true"><i class=""></i>Cet événement</a></li>
                          <li class="divider"></li>
                          <li style="cursor: pointer;"><a (click)="deleteEventSerie(eventClicked)" data-dismiss="modal"
                              aria-hidden="true">Tous les événements de la série</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
        </div>
      </form>
    </div>
  </div>
</div>

<div id="detailsEventModalDemandeRDV" class="modal fade" *ngIf="eventClicked">
  <div class="modal-dialog">
    <div class="modal-content" style="width:100%;height: 500px;">
      <div class="modal-header">
        <h4 style="color:black;margin: auto;">Demande de la prise de rendez-vous</h4>
      </div>
      <div id="modalBody" class="modal-body" style="position: relative;padding: 0px;height: 290px;">
        <form class="form-horizontal" role="form" *ngIf="!showAndHideDelete">
          <div class="col-12">
            <br>
            <h5 class="card-adress"> Motif de consultation : {{eventClicked.motif}}</h5>
            <h5 class="card-adress"> Nom et prénom du patient : {{eventClicked.nom_patient }}
              {{eventClicked.prenom_patient}}</h5>
            <h5 class="card-adress"> Email : {{eventClicked.email}}</h5>
            <h5 class="card-adress"> Numéro de téléphone personnel : {{eventClicked.tel}}</h5>
            <h5 class="card-adress"> Consultation de médecine générale : {{eventClicked.start | date: 'dd/MM/yyyy à
              HH:mm'}} </h5>
            <br>
          </div>
          <div class="modal-footer d-flex justify-content-center" style="border:none">
            <div class=" col d-flex justify-content-center buttonsPopup">
              <div class="col-auto nbr-dossier ">
                <table>
                  <tr>
                    <td class="tdButt1 ">
                      <button class="btn-valider" style="width: 115px;font-size: 13px;" data-dismiss="modal"
                        aria-hidden="true" (click)="AcceptedDemande(eventClicked)">Accepter </button>
                    </td>
                    <td class="tdButt2 ">
                      <div class="dropdown" style="float: inherit;margin-right: 5px;">
                        <button type="button" name="button" style="width: 115px;font-size: 13px;"
                          class="btn-annule dropdown-toggle outils btn btn-danger" data-toggle="dropdown"><br>Refuser</button>
                        <ul class="dropdown-menu profil-menu">
                          <li style="cursor: pointer;"><a (click)="refuserDemande(eventClicked)"><i class=""></i>La
                              demande</a></li>
                          <li class="divider"></li>
                          <li style="cursor: pointer;"><a (click)="hideDelete()">La demande et proposer une autre
                              date</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </form>
        <form class="form-horizontal" role="form" [formGroup]="deleteAndProposeForm" *ngIf="showAndHideDelete"
          style="position: relative;left: 7%;">
          <div class="col-12">
            <label for="">Jour*</label>
            <input class="form-control" formControlName="jour" name="jour" type="date" required="required"
              style="width:80%;border: none;background-color: #e8f6f5;padding: 1% 3%;width: 80%;" />
            <control-messages [control]="deleteAndProposeForm.controls.jour"></control-messages>
          </div>
          <div class="col-12">
            <label for="">De*</label>
            <input class="form-control" formControlName="start" name="start" type="time" required="required"
              style="width:80%;border: none;background-color: #e8f6f5;padding: 1% 3%;width: 80%;" />
            <p *ngIf="showErrorDate" style="color:red">Vérifier les dates</p>
            <control-messages [control]="deleteAndProposeForm.controls.start"></control-messages>
          </div>
          <div class="col-12">
            <label for="">À*</label>
            <input class="form-control" formControlName="end" name="end" type="time" required="required"
              style="width:80%;border: none;background-color: #e8f6f5;padding: 1% 3%;width: 80%;" />
            <control-messages [control]="deleteAndProposeForm.controls.end"></control-messages>
          </div>
          <div class="col-12">
            <button class="btn-valider" style="width: 115px;font-size: 13px;margin-left: 30%;"
              [disabled]="!deleteAndProposeForm.valid" (click)="proposerOtherDate(eventClicked)"><img
                src="assets/images/listePatsEspaceMed/valider.png" class="iconBoutton">Valider </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>