import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conduite-tenir',
  templateUrl: './conduite-tenir.component.html',
  styleUrls: ['./conduite-tenir.component.css']
})
export class ConduiteTenirComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
