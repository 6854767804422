
<div class="modal-header">
    <h4 class="modal-title">Motif de consultation</h4>
    <a class="exit" aria-label="Close"(click)="onReset()"><i class="fa-regular fa-circle-xmark"></i></a>
</div>
<div class="modal-body">
    <div>
        <form class="">
            <div class="form-row">
                <div *ngIf="displayList == true" class="form-group col">
                    <div class="label-btn-container mb-3">
                        <label>Motif(s)</label>
                        <button type="button" class="btn btn-primary" (click)="addMotif()" title="Nouveau motif">
                            <i class="fa-solid fa-plus" title="Nouveau motif"></i>
                        </button>
                    </div>

                    <app-reusable-ngselect
                        [items]="listMotifs"
                        [(selectedItems)]="selectedMotifs"
                        placeholder="Select Motifs"
                        [selectContainer]="{
                            'min-width': '100%'
                        }"
                        [ngSelectStyle]="{
                            'width': '100%',
                            'max-width': '100%'
                        }"
                        [selectedItemsStyle]="{
                            'width': '100%'
                        }">
                    </app-reusable-ngselect>
                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Champ obligatoire.</div>
                        </div> -->
                </div>

                <div *ngIf="displayList == false" class="form-group col">
                    <label>Nouveau motif</label>
                    <div class="form-group row mt-3">

                        <div class="col">
                            <input class="form-control" type="text" [(ngModel)]="newMotif"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="col">
                            
                            <button class="btn btn-secondary mr-2" (click)="cancelNotMotif()">Annuler</button>
                            <button (click)="saveMotif(newMotif)" class="btn btn-primary">Enregister</button>
                        </div>
                    </div>
                </div>





            </div>
            <!-- <div class="form-row">
                    <label>La/Les motif(s) selctionée(s) </label>
  
                    </div> -->

            <div *ngIf="displayList == true" class="d-flex justify-content-center mt-4">
                <!-- Ajout de la classe pour centrer -->
                <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                    <button class="btn btn-secondary mr-2" (click)="onReset()">Annuler</button>
                    <button class="btn btn-primary">Sauvegarder</button>
                </div>
            </div>
        </form>
    </div>
</div>