<app-header></app-header>
<div class="container-fluid p-sm-5 bg-light vh-100">
    <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/accueil"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/patients-liste">
                        Patients</a>
                </li>
                <li class="breadcrumb-item active">Ajouter documents</li>
            </ul>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card p-4" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                <div class="card-body">
                    <form #f0="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">


                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Type:<b style="color: red;"> *</b></label>
                                    <ng-select [items]="typeList" formControlName="type" class="form-control input-custom" [closeOnSelect]="true" [ngClass]="{ 'is-invalid': submitted && f.type.errors }"></ng-select>

                                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                        <div *ngIf="f.type.errors.required">{{champsObligatoire}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Date:<b style="color: red;"> *</b></label>
                                    <input type="datetime-local" class="form-control" formControlName="date" max="{{today |date:'yyyy-MM-dd hh:MM' }}" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
                                    <!-- <input type="datetime-local" class="form-control" formControlName="date" [ngClass]="{ 'is-invalid': submitted && f.date.errors }"> -->
                                    <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                        <div *ngIf="f.date.errors.required">{{champsObligatoire}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Statut:<b style="color: red;"> *</b></label>
                                    <ng-select [items]="satutsList" formControlName="statut" class="form-control input-custom" [closeOnSelect]="true" [ngClass]="{ 'is-invalid': submitted && f.statut.errors }"></ng-select>
                                    <div *ngIf="submitted && f.statut.errors" class="invalid-feedback">
                                        <div *ngIf="f.statut.errors.required">{{champsObligatoire}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Demandé Par:</label>
                                    <ng-select [items]="medecinsList" [(ngModel)]="selectedMedecin" [virtualScroll]="true" bindLabel="fullName" formControlName="demandePar" class="form-control input-custom" [closeOnSelect]="true"></ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <!-- input type upload file -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Résultat:</label>
                                    <input type="file" [(ngModel)]="resultss" class="form-control" formControlName="resultat" (change)="fileChange($event)" multiple accept="image/*,.pdf">
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col">
                                <div class="form-group display-Flex ">
                                    <label>Autoriser l'accès au patient:</label>
                                    <label class="switch ml-2">
                                        <input type="checkbox" formControlName="autoriserAcces">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div class="form-group text-right">
                            <button type="submit" class="btn btn-primary">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>