import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interrogatoire',
  templateUrl: './interrogatoire.component.html',
  styleUrls: ['./interrogatoire.component.css']
})
export class InterrogatoireComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
