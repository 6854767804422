import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HistoriqueConsultationComponent } from '../../popups/historique-consultation/historique-consultation.component';

@Component({
  selector: 'app-examen-details',
  templateUrl: './examen-details.component.html',
  styleUrls: ['./examen-details.component.css']
})
export class ExamenDetailsComponent implements OnInit {

  selectedMotifs: any[] = [];
  listMotifs: any;

  patientDetails = {
    nom: 'Baha Jemni',
    age: 22,
    dateNaissance: '',
    // dateNaissance: '22/10/1993',
  };

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initialisationMotifs();
  }

  initialisationMotifs(): void {
    this.listMotifs = [
      "Douleurs musculaires et articulaires",
      "Fatigue générale",
      "Maux de tête fréquents",
      "Troubles du sommeil",
      "Infections respiratoires (rhume, grippe, bronchite)",
      "Problèmes digestifs (maux de ventre, ballonnements, diarrhée, constipation)",
      "Douleurs abdominales",
      "Fièvre persistante",
      "Toux persistante",
      "Douleurs thoraciques"
    ]
  }

  historiqueConsultationModal() {
    const modalRef = this.modalService.open(HistoriqueConsultationComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.consultationHistorique.subscribe(() => {
      console.log("Test modal done")
    })
  }

}
