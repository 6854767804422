import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { AuthService } from '../services/auth/auth.service';

import { PatientService } from '../services/patient/patient.service';
import { getTodayDateFormatted } from '../utils/date-utils';
import { RdvsService } from '../services/rdvs/rdvs.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  myBarChart: any = [];
  canvas: any;
  ctx: any;
  rdvs: any[] = [];
  sizeContent: any;
  patientInfo: any = {};
  patients: any[] = [];
  sizeContentListPatients: number;
  date: string;
  medecinId: string | null;

  constructor(
    private authService: AuthService,
    private rdvService: RdvsService,
    private patientService: PatientService
  ) {
  }

  ngOnInit(): void {

    const medecinString = localStorage.getItem('medecin');
    this.medecinId = JSON.parse(medecinString).id;
    this.date = getTodayDateFormatted();
    this.getRdvsToday();
    this.sizeContent = this.rdvs.length
    this.patientInfo = this.rdvs[0]
    this.sizeContentListPatients = this.patients.length


    this.patientService.getLastTwentyPatient().subscribe((data: any) => {
      if (data && data.success) {
        this.patients = data.data;
        this.sizeContentListPatients = this.patients.length
      }
    },
      err => {
        return false;
      });




  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('myChart');
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');

      let myChart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
          datasets: [{
            label: ' Nombres des patients',
            data: [7, 8, 8, 5, 14],
            backgroundColor: [
              '#589590',
              '#23B1A5',
              '#589590',
              '#23B1A5',
              '#589590'
            ],
            borderWidth: 5
          }]
        },
        options: {
          legend: {
            display: false,
          },
          responsive: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      });
    }
    else {
      return false
    }

  }

  getRdvsToday(): void {
    // this.rdvService.getRdvsToday(this.date, this.medecinId).subscribe(
    //   data => {
    //     this.rdvs = data;
    //     if (this.rdvs.length > 0) {
    //       this.patientInfo = this.rdvs[0];
    //     }
    //   },
    //   error => {
    //     console.log('Error fetching rdvs:', error);
    //   }
    // )
  }


  getInfoPatient(idRdv) {
    console.log("idRdv", idRdv)
    const rdvInfo = this.rdvs.find(rdv => rdv._id === idRdv);
    console.log("rdvInfo", rdvInfo)
    if (rdvInfo) {
      this.patientInfo = rdvInfo;
      this.patientInfo.age = this.calculateAge(this.patientInfo.dateAnniversaire);
    }
    //   if (!idRdv || rdvInfo) {
    // console.log("Rendez-vous trouvé :", idRdv);
    // this.patientInfo =this.rdvs[0]
  }


  calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
}
