<div class="modal-header">
    <h5 class="mb-0">Historique des consultations</h5>
    <a class="exit" aria-label="Close" (click)="onReset()">
      <i class="fa-regular fa-circle-xmark"></i>
    </a>
</div>

<div class="modal-body">

    <table class="table">
        <thead>
            <tr class="ligne-paire">
                <th class="text-center table-title">Date</th>
                <th class="text-center table-title">Motif de consultation</th>
                <th class="text-center table-title">Durée</th>
                <!-- Ajoutez d'autres colonnes au besoin -->
            </tr>
        </thead>
        <tbody>
            <tr class="ligne-impaire">
                <td class="text-center">2024-05-01</td>
                <td class="text-center">Motif</td>
                <td class="text-center">00:15:00</td>
                <!-- Ajoutez d'autres cellules au besoin -->
            </tr>
            <tr class="ligne-paire">
                <td class="text-center">2024-05-01</td>
                <td class="text-center">Motif</td>
                <td class="text-center">00:15:00</td>
                <!-- Ajoutez d'autres cellules au besoin -->
            </tr>
            <tr class="ligne-impaire">
                <td class="text-center">2024-05-01</td>
                <td class="text-center">Motif</td>
                <td class="text-center">00:15:00</td>
                <!-- Ajoutez d'autres cellules au besoin -->
            </tr>
            <tr class="ligne-paire">
                <td class="text-center">2024-05-01</td>
                <td class="text-center">Motif</td>
                <td class="text-center">00:15:00</td>
                <!-- Ajoutez d'autres cellules au besoin -->
            </tr>
            <tr class="ligne-impaire">
                <td class="text-center">2024-05-01</td>
                <td class="text-center">Motif</td>
                <td class="text-center">00:15:00</td>
                <!-- Ajoutez d'autres cellules au besoin -->
            </tr>
        </tbody>
    </table>

    <hr class="split-btns" />
    <div class="buttons">
        <button class="btn btn-secondary mr-2" (click)="onReset()">Fermer</button>
    </div>
</div>