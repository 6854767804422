import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { AddRdvComponent } from './add-rdv/add-rdv.component';
import { UpdateRdvComponent } from './update-rdv/update-rdv.component';
import { ToastService } from '../services/toast/toast.service';
import { ConfirmationComponent } from '../popups/confirmation/confirmation.component';
import { RdvsService } from '../services/rdvs/rdvs.service';
import { AnnulationComponent } from '../popups/annulation/annulation.component';

@Component({
  selector: 'app-rdvs',
  templateUrl: './rdvs.component.html',
  styleUrls: ['./rdvs.component.css']
})
export class RdvsComponent implements OnInit {

  page: number = 1;
  rdvs:any[];
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  count: number = 0;
  copyListRdvs: any[];

  idPatientToFiltred: string = '';
  numFicheToFiltred: string = '';
  nomPrenomToFiltred: string = '';
  dateToFiltred: string = '';
  statusToFiltred: string = '';

  medecinId: string | null;

  attConf: boolean = false;

  constructor(
    private modalService: NgbModal,
    private config: NgSelectConfig,
    private rdvService: RdvsService,
    private toastService: ToastService
  ) { 
    this.config.notFoundText = "Aucun élément trouvé" 
  }
  
  ngOnInit(): void {
    this.getMedecinId()
    this.initializeRdvs();
    this.copyListRdvs = this.rdvs;
  }

  getMedecinId() {
    if (localStorage.getItem("medecin")) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.medecinId = medecinId
    } else {
      return
    }
  }

  initializeRdvs() {
    this.rdvService.getRdvsByMedecinId(this.medecinId).subscribe({
      next: (data: any) => {
        this.rdvs = data.map(rdv => {
          // Ensure rdv.start is defined before splitting
          const [date, time] = rdv.start ? rdv.start.split('T') : ['', ''];

          if (rdv.status === "En attente de confirmation") {
            this.attConf = true;
          }

          return {
            ...rdv,
            date,
            startTime: time ? time.split(':').slice(0, 2).join(':') : '',
            nom_patient: rdv.patientId?.nom, // Access populated patient data
            prenom_patient: rdv.patientId?.prenom,
            numFiche: rdv.patientId?.numFiche
          };
        });
        this.copyListRdvs = [...this.rdvs];
        this.count = this.rdvs.length;
      },
      error: (err) => console.error('Failed to fetch RDVs', err)
    });
  }

  addRdvModal() {
    const modalRef = this.modalService.open(AddRdvComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.rdvAdded.subscribe(() => {
      this.initializeRdvs();
    });
  }

  updateRdv(rdvId: string) {
    // TODO : Implémentation d'update d'un rendez-vous (utilisation de modal et retriving de Data )
    const modalRef = this.modalService.open(UpdateRdvComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.rdvId = rdvId;
    modalRef.componentInstance.rdvUpdated.subscribe(() => {
      this.initializeRdvs();
    });
  }

  annulerRdv(rdvId: string) {
    this.rdvService.annulerRdv(rdvId).subscribe({
      next: () => {
        this.toastService.showSuccess("Rendez-vous annulé avec succès.");
        this.initializeRdvs();
      },
      error: (err) => {
        this.toastService.showError("Erreur lors de l'annulation du rendez-vous.")
      }
    })
  }

  confirmRdv(rdvId: string) {
    this.rdvService.confirmerRdv(rdvId).subscribe({
      next: (response) => {
        this.toastService.showSuccess('Rendez-vous confirmé avec succès');
        this.initializeRdvs();
      },
      error: (err) => {
        this.toastService.showError('Échec de la confirmation de rendez-vous');
      }
    })
  }

  modalConfirmRdv(rdvId: string) {
    const modalRef = this.modalService.open(ConfirmationComponent);
    modalRef.componentInstance.rdvId = rdvId;
    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.confirmRdv(rdvId);
      }
    }, (reason) => {
      // Handle dismiss case
    });
  }

  modalAnnulerRdv(rdvId: string){
    const modalRef = this.modalService.open(AnnulationComponent);
    modalRef.componentInstance.rdvId = rdvId;
    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.annulerRdv(rdvId);
      }
    }, (reason) => {
      // Handle dismiss case
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  /* consulterRdv(rdvId: string){
    // TODO : Consulter un rendez-vous
  } */

  filter() {
    const idPatientFilterLower = this.idPatientToFiltred.toLowerCase();
    const numFicheFilterLower = this.numFicheToFiltred.toLowerCase();
    const nomPrenomFilterLower = this.nomPrenomToFiltred.toLowerCase();
    const dateFilter = this.dateToFiltred;
    const statusFilter = this.statusToFiltred.toLowerCase();
  
    this.rdvs = this.copyListRdvs.filter(item => {
      const fullName = `${item.nom_patient} ${item.prenom_patient}`.toLowerCase();
      return (
        (idPatientFilterLower ? item.idPatient.toLowerCase().includes(idPatientFilterLower) : true) &&
        (numFicheFilterLower ? item.numFiche.toLowerCase().includes(numFicheFilterLower) : true) &&
        (nomPrenomFilterLower ? fullName.includes(nomPrenomFilterLower) : true) &&
        (dateFilter ? item.date === dateFilter : true) &&
        (statusFilter ? item.status.toLowerCase() === statusFilter : true)
      );
    });
  
    this.page = 1; // Reset pagination
  }

  resetFilters() {
    this.idPatientToFiltred = '';
    this.numFicheToFiltred = '';
    this.nomPrenomToFiltred = '';
    this.dateToFiltred = '';
    this.statusToFiltred = '';
    this.filter();
  }  

}
