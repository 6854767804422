<div class="container-fluid content-container">
  <div class="row">
    <div *ngFor="let systemData of getSystemData()" class="col-12 col-xl-6 mb-4">
      <div class="card form-card">
        <div class="card-body">
          <b class="form-item-menu ml-2">
            {{ systemData.system | titlecase }}
          </b>
          <div class="card-content mt-3">
            <div class="select-item">
              <!-- <label class="form-title">
                {{ systemData.system | titlecase }}
              </label> -->
              <div class="checkboxes row">
                <div *ngFor="let symptom of systemData.symptoms" class="col-5 checkbox-container">
                  <label class="checkbox-label">
                    <input 
                      type="checkbox" 
                      [name]="symptom.key"
                      [checked]="getSymptomDefaultValue(systemData.system, symptom.key)">
                    <span class="custom-checkbox"></span>
                    {{ symptom.description }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>