import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { _getURL, API_CONFIG } from 'config/API_CONFIG';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }
  //get documents
  getDocuments() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document), { headers: headers })

  }
  getDocumentsObecjtsPatient(id, idMedecin) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document) + "/patient/documents/objects/" + id + "/" + idMedecin, { headers: headers })

  }

  saveDocumentByPatientDocuement(file) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.document) + '/add/new/patient/document/objects', file, { headers: headers })
  }
  saveDocumentByMedecin(file, id, key) {
    return this.http.post(_getURL(API_CONFIG.document) + '/medecin/patient/upload/files/' + id + '/' + key, file)
  }
  updateDocumentByMedecin(file, id, key) {
    return this.http.post(_getURL(API_CONFIG.document) + '/medecin/patient/upload/files/update/' + id + '/' + key, file)
  }
  onUpdateDocuementPatient(document) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.document) + '/' + document._id, document, { headers: headers })

  }
  getByPatientDocuement(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document) + '/medecin/patient/details/document/' + id, { headers: headers })

  }
  deletePatientDocuement(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.document) + '/patient/documents/object/' + id, {}, { headers: headers })
  }
  getFileNamesSharedBypatient(id, idPatient) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.document) + '/patient/documents/partage/listNames/' + id + '/' + idPatient, { headers: headers })
  }

}
