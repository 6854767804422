import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PatientComponent } from './patient/patient.component';
import { AddPatientComponent } from './patient/add-patient/add-patient.component';
import { UpdatePatientComponent } from './patient/update-patient/update-patient.component';
import { MotifConsultationComponent } from './popups/motif-consultation/motif-consultation.component';
import { MedecinComponent } from './medecin/medecin.component';
import { ConsultationHeaderComponent } from './shared/consultation-header/consultation-header.component';
import { ConclusionComponent } from './consultation/conclusion/conclusion.component';
import { ConduitATenirComponent } from './consultation/conduit-atenir/conduit-atenir.component';
import { SuppressionComponent } from './popups/suppression/suppression.component';
import { SearchMedecinComponent } from './search-medecin/search-medecin.component';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoginComponent } from './login/login.component';
import { ConsultationListComponent } from './consultation/consultation-list/consultation-list.component';
import { ProfileComponent } from './profile/profile.component';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { PopupSearchPatientComponent } from './popups/search-patient/popup-search-patient.component';
import { AjoutDocumentPatientComponent } from './documents/ajout-document-patient/ajout-document-patient.component';
import { UpdateDocumentPatientComponent } from './documents/update-document-patient/update-document-patient.component';
import { DocumentsListComponent } from './popups/documents-list/documents-list.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { CalendrierComponent } from './calendar/calendar.component';
import { ControlMessagesComponent } from './messages/control-messages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConfirmationComponent } from './popups/confirmation/confirmation.component';
import { AnnulationComponent } from './popups/annulation/annulation.component';
import { RdvsComponent } from './rdvs/rdvs.component';
import { AddRdvComponent } from './rdvs/add-rdv/add-rdv.component';
import { UpdateRdvComponent } from './rdvs/update-rdv/update-rdv.component';
import { SalleAttComponent } from './salle-att/salle-att.component';
import { OrderByPipe } from './pipes/orderByPipe/order-by.pipe';
import { ExamenComponent } from './examen/examen.component';
import { ExamenDetailsComponent } from './examen/examen-details/examen-details.component';
import { ExamenSidebarComponent } from './examen/examen-sidebar/examen-sidebar.component';
import { InterrogatoireComponent } from './examen/examen-content/interrogatoire/interrogatoire.component';
import { ExamComponent } from './examen/examen-content/exam/exam.component';
import { AntecedMedComponent } from './examen/examen-content/interrogatoire/anteced-med/anteced-med.component';
import { SystematiqueComponent } from './examen/examen-content/interrogatoire/systematique/systematique.component';
import { ConduiteTenirComponent } from './examen/examen-content/conduite-tenir/conduite-tenir.component';
import { DocumentsComponent } from './examen/examen-content/documents/documents.component';
import { ConclusionsComponent } from './examen/examen-content/conclusions/conclusions.component';
import { ReusableNgselectComponent } from './shared/components/reusable-ngselect/reusable-ngselect.component';
/* import { ImageAnalyzerComponent } from './ai-models/image-analyzer/image-analyzer.component';
import { AiModelsComponent } from './ai-models/ai-models.component';
import { OccularDisRecoComponent } from './ai-models/occular-dis-reco/occular-dis-reco.component'; */
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PatientComponent,
    AddPatientComponent,
    UpdatePatientComponent,
    MotifConsultationComponent,
    MedecinComponent,
    SearchMedecinComponent,
    ConsultationHeaderComponent,
    ConclusionComponent,
    ConduitATenirComponent,
    SuppressionComponent,
    LoginComponent,
    ConsultationListComponent,
    ProfileComponent,
    PopupSearchPatientComponent,
    AjoutDocumentPatientComponent,
    UpdateDocumentPatientComponent,
    DocumentsListComponent,
    CalendrierComponent,
    NotFoundComponent,
    ConfirmationComponent,
    ControlMessagesComponent,
    AnnulationComponent,
    RdvsComponent,
    AddRdvComponent,
    UpdateRdvComponent,
    SalleAttComponent,
    OrderByPipe,
    ExamenComponent,
    ExamenDetailsComponent,
    ExamenSidebarComponent,
    InterrogatoireComponent,
    ExamComponent,
    AntecedMedComponent,
    SystematiqueComponent,
    ConduiteTenirComponent,
    DocumentsComponent,
    ConclusionsComponent,
    ReusableNgselectComponent,
    /* ImageAnalyzerComponent,
    AiModelsComponent,
    OccularDisRecoComponent, */
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    NgxIntlTelInputModule,
    NgSelectModule,
    Ng2IziToastModule,
    InternationalPhoneNumberModule,
    RecaptchaV3Module,

  ],
  providers: [{
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
