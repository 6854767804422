import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-systematique',
  templateUrl: './systematique.component.html',
  styleUrls: ['./systematique.component.css']
})
export class SystematiqueComponent implements OnInit {

  interrogatoireSystematique = {
    general: {
      fievre: { type: Boolean, default: false, description: 'Fièvre' },
      pertePoids: { type: Boolean, default: true, description: 'Perte de poids' },
      fatigue: { type: Boolean, default: false, description: 'Fatigue' }
    },
    cardiovasculaire: {
      douleurThoracique: { type: Boolean, default: false, description: 'Douleur thoracique' },
      palpitations: { type: Boolean, default: true, description: 'Palpitations' },
      oedemeJambes: { type: Boolean, default: false, description: 'Œdème des jambes' }
    },
    respiratoire: {
      toux: { type: Boolean, default: false, description: 'Toux' },
      essoufflement: { type: Boolean, default: false, description: 'Essoufflement' },
      sifflements: { type: Boolean, default: false, description: 'Sifflements' }
    },
    gastroIntestinal: {
      nausees: { type: Boolean, default: true, description: 'Nausées' },
      vomissements: { type: Boolean, default: false, description: 'Vomissements' },
      diarrhee: { type: Boolean, default: true, description: 'Diarrhée' },
      douleurAbdominale: { type: Boolean, default: false, description: 'Douleur abdominale' }
    },
    neurologique: {
      mauxDeTete: { type: Boolean, default: false, description: 'Maux de tête' },
      vertiges: { type: Boolean, default: false, description: 'Vertiges' },
      engourdissements: { type: Boolean, default: true, description: 'Engourdissements' }
    },
    musculoSquelettique: {
      douleursArticulaires: { type: Boolean, default: true, description: 'Douleurs articulaires' },
      faiblesseMusculaire: { type: Boolean, default: true, description: 'Faiblesse musculaire' }
    },
    cutane: {
      eruptionCutane: { type: Boolean, default: false, description: 'Éruption cutanée' },
      demangeaisons: { type: Boolean, default: true, description: 'Démangeaisons' },
      lesions: { type: Boolean, default: false, description: 'Lésions' }
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

  getSystemData() {
    return Object.entries(this.interrogatoireSystematique).map(([system, symptoms]) => ({
      system,
      symptoms: Object.entries(symptoms).map(([key, value]) => ({
        key,
        description: value.description
      }))
    }));
  }

  getSymptomDefaultValue(system: string, symptomKey: string): boolean {
    return this.interrogatoireSystematique[system][symptomKey]?.default || false;
  }

}