import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private iziToast: Ng2IzitoastService
  ) { }

  showSuccess(message: string) {
    this.iziToast.show({
      message: message,
      messageColor: '#386641',
      progressBarColor: '#6a994e',
      position: 'topRight',
      timeout: 5000,
      backgroundColor: '#dde5b6',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX'
    });
  }

  showError(message: string) {
    this.iziToast.show({
      message: message,
      messageColor: '#800f2f',
      titleColor: '#800f2f',
      progressBarColor: '#c9184a',
      position: 'topRight',
      timeout: 5000,
      backgroundColor: '#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay: true,
      overlayClose: true
    });
  }
}
