import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _getURL, API_CONFIG } from 'config/API_CONFIG';

@Injectable({
  providedIn: 'root'
})
export class ProfessionService {

  private apiUrl = _getURL(API_CONFIG.professions)

  constructor(
    private http: HttpClient
  ) { }

  createProfession(newProfession) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl, newProfession, {headers: headers})
  }

  getProfession(idProfession) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/' + idProfession, {headers: headers})
  }

  getAllProfessions() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl, {headers: headers})
  }

  deleteProfession(idProfession) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.delete(this.apiUrl + '/' + idProfession, {headers: headers})
  }

}
