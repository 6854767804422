<div class="modal-header">
    <h4 class="modal-title">Suppression d'un {{name}}</h4>
    <a class="exit" aria-label="Close" (click)="onReset()"><i class="fa-regular fa-circle-xmark"></i></a>
</div>
<div class="modal-body">
    <div>
        <form class="">
            <div class="form-row justify-content-center align-items-center ">
                <p class="text-center">Voulez-vous supprimer ce {{name}}</p>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <!-- Ajout de la classe pour centrer -->
                <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                    <button class="btn btn-secondary mr-2" (click)="onReset()">Annuler</button>
                    <button class="btn btn-primary" data-dissmiss (click)="confirm()">Supprimer</button>
                </div>
            </div>
        </form>
    </div>
</div>