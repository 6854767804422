import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-historique-consultation',
  templateUrl: './historique-consultation.component.html',
  styleUrls: ['./historique-consultation.component.css']
})
export class HistoriqueConsultationComponent implements OnInit {
  @Output() consultationHistorique = new EventEmitter<void>();

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  onReset() {
    this.modal.dismiss();
  }

}
