import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { _getURL } from 'config/API_CONFIG';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.css']
})
export class DocumentsListComponent implements OnInit {
  files: any[];
  sharedFiles: any[];
  document: any
  idPatient: any
  constructor(public modal: NgbActiveModal,) { }

  ngOnInit(): void {
  }
  onReset() {
    this.modal.dismiss();
  }
  // Files added by doctor functions
  openFile(filePath) {
    let path = filePath.slice(8)
    window.open(`${_getURL("")}data/files/${path}`, '_blank')
  }
  downloadFile(filePath) {
    let path = filePath?.lien.slice(8)
    // URL of the document to download
    const documentUrl = `${_getURL("")}data/files/${path}`; // Replace with your document URL
    const documentName = filePath.name; // Replace with the name you want for the downloaded document


    // Fetch the document as a Blob
    fetch(documentUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = documentName;
        anchor.style.display = 'none';
        anchor.target = 'blank';

        document.body.appendChild(anchor);
        anchor.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
  // shared files functions
  openSharedFile(file) {
    window.open(`${_getURL("")}data/files/documents/${this.document?.patient}/${this.document?.key}/${file}`, '_blank')
  }
  downloadSharedFiles(file) {
    const documentName = file
    const documentUrl = `${_getURL("")}data/files/documents/${this.document?.patient}/${this.document?.key}/${file}`
    // Fetch the document as a Blob
    fetch(documentUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        console.log(documentName);
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = documentName;
        anchor.style.display = 'none';
        anchor.target = 'blank';

        document.body.appendChild(anchor);
        anchor.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }


}
