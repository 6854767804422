<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light">
  <form [formGroup]="form">
    <div class="row mt-2 justify-content-center">
      <div class="col-md-4 mr-3">
        <div class="input-group my-3">
          <input type="text" class="form-control" onkeypress="return /[a-zA-Z ]/.test(event.key)" placeholder="Nom du médecin" style="height: 50px;" formControlName="medecin">
          <div class="input-group-append">
            <span class="input-group-text"><i class="fas fa-user-md"></i></span>
          </div>
        </div>
        <!-- <control-messages [control]="form.controls.medecin"></control-messages> -->
      </div>


      <div class="col-md-3 mr-3">
        <div class="input-group my-3">
          <ng-select style="height: 50px;" [items]="specialites" class="form-control input-custom" placeholder="Spécialité" [closeOnSelect]="true" formControlName="specialite">
          </ng-select>
          <div class="input-group-append">
            <span class="input-group-text"><i class="fas fa-stethoscope"></i></span>
          </div>
        </div>
        <!-- <control-messages [control]="form.controls.specialite"></control-messages> -->
      </div>
      <div class="col-md-3 mr-3">
        <div class="input-group my-3">
          <ng-select style="height: 50px;" [items]="villes" class="form-control input-custom" placeholder="Localisation" [closeOnSelect]="true" formControlName="ville">
          </ng-select>
          <div class="input-group-append">
            <span class="input-group-text"><i class="fas fa-map-marker-alt"></i></span>
          </div>
        </div>
        <!-- <control-messages [control]="form.controls.ville"></control-messages> -->
      </div>
      <div class="col-md-1 mt-3">
        <button class="btn btn-connexion" (click)="recherche()">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </form>

  <div style="margin-bottom: 3rem"></div>
  <div class="container">
    <div class="row justify-content-center" *ngIf="medecinss && medecinss.length > 0">
      <div class="col-12 mb-4" *ngFor="let medecin of medecinss">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Dr {{ medecin.nom }} {{ medecin.prenom }}
            </h5>
            <p class="card-text">
              <span class="label"><i class="fas fa-stethoscope"></i> Spécialité:
              </span>
              <span class="badge bg-color badge-responsive">{{ medecin.specialite }}</span>
            </p>
            <p class="card-text">
              <span class="label"><i class="fa-solid fa-location-dot"></i> Adresse:
              </span>
              <span>{{ medecin.adresseCabinet.adresse }}</span>
            </p>
            <p class="card-text">
              <span class="label"><i class="fa-solid fa-map-location"></i> Ville:
              </span>
              <span>{{ medecin.adresseCabinet.localite }}, {{ medecin.adresseCabinet.ville }}</span>
            </p>
            <!-- <a *ngIf="!medecin.favorite" class="btn btn-favorite" style="color: #21726b;float: right;" (click)="toggleFavorite(medecin)">
              <i class="fa-regular fa-heart" style="font-size: 1.7rem;"></i>
            </a>
            <a *ngIf="medecin.favorite" class="btn btn-favorite" style="color: #21726b;float: right;" (click)="toggleFavorite(medecin)">
              <i class="fa-solid fa-heart" style="font-size: 1.7rem;"></i>
            </a>
            <a class="btn btn-favorite" style="color: #21726b;float: right;" (click)="chat()">
              <i class="fa-regular fa-message" style="font-size: 1.7rem;"></i>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>