<div class="modal-header">
    <h4 class="modal-title">Liste des fichiers</h4>
    <a class="exit" aria-label="Close" (click)="onReset()">
        <i class="fa-regular fa-circle-xmark"></i>
    </a>
</div>
<div class="modal-body">
    <div>
        <div *ngIf="files">
            <p *ngIf="files.length===0" class="d-flex justify-content-center mt-2">Aucun fichier n'est disponible.</p>
            <ul class="list-group" *ngFor="let file of files">
                <li class="list-group-item d-flex justify-content-between mb-2">
                    <a (click)="openFile(file.lien)" class="colorBlack" title="Ouvrir le fichier">{{file.name}}</a>
                    <div>
                        <a (click)="openFile(file.lien)"><i class="fa-regular fa-eye mr-1" title="Ouvrir le fichier"></i></a>
                        <a (click)="downloadFile(file)"> <i class="fa-solid fa-download mr-1" title="Télécharger le fichier"></i></a>
                        <!-- <a><i class="fa-solid fa-print" title="Imprimer le fichier"></i></a> -->
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="sharedFiles">
            <p *ngIf="sharedFiles.length===0" class="d-flex justify-content-center mt-2">Aucun fichier n'est disponible.</p>
            <ul class="list-group" *ngFor="let file of sharedFiles">
                <li class="list-group-item d-flex justify-content-between mb-2">
                    <a (click)="openSharedFile(file)" class="colorBlack" title="Ouvrir le fichier">{{file}}</a>
                    <div>
                        <a (click)="openSharedFile(file)"><i class="fa-regular fa-eye mr-1" title="Ouvrir le fichier"></i></a>
                        <a (click)="downloadSharedFiles(file)"> <i class="fa-solid fa-download mr-1" title="Télécharger le fichier"></i></a>
                        <!-- <a><i class="fa-solid fa-print" title="Imprimer le fichier"></i></a> -->
                    </div>
                </li>
            </ul>
        </div>


        <div class="d-flex justify-content-center mt-4">
            <!-- Ajout de la classe pour centrer -->
            <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                <!-- <button class="btn btn-secondary mr-2" (click)="onReset()">Annuler</button> -->
                <button class="btn btn-primary" (click)="onReset()">Confirmer</button>
            </div>
        </div>
    </div>
</div>