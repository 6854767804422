import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { apciMaxElements, champMaxLength, champMinLength, champsObligatoire, invalidEmailAddress, invalidNumTelephone } from 'src/app/services/constants/messages';
import { Patient } from 'src/app/services/patient/patient.model';
import { PatientService } from 'src/app/services/patient/patient.service';
import postCode from 'src/app/services/constants/TN_Postal_codes.json';
import countries from 'src/app/services/constants/List_countries.json';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfessionService } from 'src/app/services/professions/profession.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-update-patient',
  templateUrl: './update-patient.component.html',
  styleUrls: ['./update-patient.component.css']
})
export class UpdatePatientComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  patientId: string;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  Sexes = ['Homme', 'Femme']
  statusFamiliales = ['Célibataire', 'Marié(e)', 'Divorcé(e)', 'Veuf(ve)']
  groupeSanguins = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']
  apcis = [
    'Affections coronariennes et leurs complications', 'Affections hypophysaires', 'Affections surrénaliennes', 'Cardiopathies congénitales et valvulopathies',
    'Cirrhoses et insuffisance hépatique', 'Diabète insulinodépendant ou non insulinodépendant', 'Dysthyroïdies', 'Epilepsie', 'Glaucome', 'HTA sévère', 'Hépatites chroniques actives',
    'Insuffisance cardiaque et troubles du rythme', 'Insuffisance respiratoire chronique', 'Insuffisance rénale chronique', 'Les maladies inflammatoires chroniques de l\'intestin',
    'Maladie de Parkinson', 'Maladies auto-immunes', 'Mucoviscidose', 'Phlébites', 'Psychoses et névroses', 'Rhumatismes inflammatoires chroniques', 'Sclérose en plaques',
    'Tuberculose active', 'Tumeurs et hémopathies malignes'
  ]
  typeOrgs = ['CNAM', 'CNSS']
  governorates: any[];
  delegs: any[];
  cites: any;
  codePostalChoise: any = "";
  today = new Date()
  // message d'erreur
  champsObligatoire = champsObligatoire
  champMinLength = champMinLength
  champMaxLength = champMaxLength
  invalidEmailAddress = invalidEmailAddress
  invalidNumTelephone = invalidNumTelephone
  apciMaxElements = apciMaxElements
  // end message d'erreur
  codePostalList: any = []
  list_contries = countries
  postal_codes = postCode
  medecin: any
  patient: any
  age: any

  filteredProfessions: string[] = [];
  professionInput: string = '';

  professions:any[] = []

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private professionService: ProfessionService,
    private toastService: ToastService
  ) {
    let params: any = this.activatedRoute.snapshot.params;
    this.patientId = params.id;
    this.loadPatientData(this.patientId)
  }

  ngOnInit(): void {
    this.initializeGovernotates();
    this.initializeForm();
    this.loadMedecinProfile();
    this.getAllProfessions();
  }

  initializeGovernotates(): void {
    this.governorates = [...new Set(this.postal_codes.map(item => item.Gov))];
  }

  initializeForm(): void {
    this.registerForm = this.formBuilder.group({
      numFiche: ['', [Validators.required, Validators.maxLength(25),]],
      nom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      prenom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      dateAnniversaire: ['', Validators.required],
      dateDeces: [''],
      email: ['', Validators.email],
      tel: ['', Validators.required],
      telsec: ['', Validators.required],
      profession: [''],
      groupeSanguin: [''],
      sexe: ['', Validators.required],
      statusFamilial: ['',],
      ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      pays: [''],
      codePostal: ['', [Validators.maxLength(4)]],
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
        pays: [''],
        codePostal: ['', [Validators.maxLength(4)]],
      }),
      apci: [[],],
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        codeCNAM: [''],
        codeCNSS: [''],
        nomEtablissement: ['', [Validators.maxLength(25)]],
        email: ['',],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: ['', [Validators.maxLength(25)]],
          codePostal: ['', [Validators.maxLength(4)]],
          adresse: ['', Validators.maxLength(500)],
        }),
      }),
      notes: [''],
      isDead: [false]
    });
  }

  loadMedecinProfile(): void {
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/']);
          return false;
        }
      },
      err => {
        return false;
      });
    }
  }

  get f() { return this.registerForm.controls; }

  loadPatientData(patientId: string) {
    this.patientService.getPatientByID(patientId).subscribe(
      (patient: any) => {
        this.patient = patient
        this.registerForm.patchValue(patient);

        if (this.registerForm.get('dateDeces').value) {
          this.registerForm.get('isDead').setValue(true);
        }
      },
      err => {
        this.router.navigate(['/medecin/patients-liste'])
        return false;
      }
    );
  }

  getAllProfessions() {
    this.professionService.getAllProfessions().subscribe(
      (professions: any[]) => {
        this.professions = professions;
      },
      err => {
        console.error('Error loading professions', err);
        return false;
      }
    )
  }

  calcuateAge() {
    var today = new Date();
    var birthDate = new Date(this.registerForm.value.dateAnniversaire);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }

    this.registerForm.value.age = this.age;
  }

  onIsDeadChange(event: any) {
    const isDead = event.target.checked;

    if (isDead) {
      this.registerForm.get('dateDeces').setValidators([Validators.required]);
    } else {
      this.registerForm.get('dateDeces').clearValidators();
    }
    this.registerForm.get('dateDeces').updateValueAndValidity();
  }

  updatePatientProfile() {
    const patientModified = { ...this.patient, ...this.registerForm.value };
    this.patientService.editProfil(patientModified).subscribe(
      (patient: any) => {
        this.toastService.showSuccess("Bien modifié");
        this.router.navigate(['/medecin/consultation']);
      },
      err => {
        console.error("Error updating patient profile!", err)
      }
    )
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    if (this.registerForm.value.tel) {
      this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber
    }

    if (this.registerForm.value.telsec) {
      this.registerForm.value.telsec = this.registerForm.value.telsec.internationalNumber
    }
   
    this.calcuateAge();
    this.updatePatientProfile();
  }

  addProfession(professionTitle: string): void {
    this.professionService.createProfession({ title: professionTitle })
    .subscribe((newProfession) => {
      this.professions.push(newProfession)
    },
    (err) => {
      console.error("Failed to add Profession", err);
    })
  }

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value.trim();
    this.professionInput = inputValue;
  
    // Filter the professions for the dropdown based on the input
    this.filteredProfessions = this.professions
      .map((profession) => profession.title)
      .filter((title) =>
        title.toLowerCase().includes(inputValue.toLowerCase())
      );
  }
  
  onBlur(): void {
    const inputValue = this.professionInput.trim();
  
    // Check if inputValue is not in predefined professions
    if (
      inputValue &&
      !this.professions.some((profession) => profession.title === inputValue)
    ) {
      // Call CRUD service to add a new profession
      this.addProfession(inputValue);
    }
  }

  getPays() {
    this.registerForm.get('adresse.ville').patchValue('');
    this.registerForm.get('adresse.codePostal').patchValue('');
    this.registerForm.get('adresse.adresse').patchValue('');
    this.codePostalChoise = ""
  }

  getVille(cov) {
    this.registerForm.get('adresse.codePostal').patchValue('');
    let listdelegs = this.postal_codes.filter(x => x.Gov === cov)
    // Extraction des noms des délégations
    const delegsNames = listdelegs.map(deleg => deleg.Deleg);
    // Élimination des doublons en utilisant un Set
    this.delegs = [...new Set(delegsNames)];
    this.codePostalList = [...new Set(listdelegs.map(deleg => deleg.zip))]
  }

}
