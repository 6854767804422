import { Component, OnInit } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MedecinService } from '../services/medecin/medecin.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { specialites } from '../services/constants/pays/specialites';
import { villes } from '../services/constants/pays/villes';


@Component({
  selector: 'app-medecin',
  templateUrl: './medecin.component.html',
  styleUrls: ['./medecin.component.css']
})
export class MedecinComponent implements OnInit {
  form: any;
  medecin: any;
  interval: any;
  messages: any[] = [];
  geolocationPosition: any;
  mesMedecins: any[] = [];
  medecinshuffle: any[] = [];
  medecins: any[] = [];
  secretaire: any;
  specialite: any;
  nom: any;
  ville: any;
  medecinsearched: any[] = []
  medecinss: any[] = [];
  medecinsScrapedSearched: any[] = []
  showedValue: any;
  firstArray: any[] = []
  thirdArray: any[] = []
  secondArray: any[] = []
  seconddArray: any[] = []
  allArray: any[] = []
  villes: any;
  lengthMedecin: any;
  pageNumber = 0
  public options: any;
  public medicamentsData: Array<any>;
  public villeData: Array<any>;
  staticMedecinscraped: any[] = [];
  selectedMedecinn: any;
  showedValueSpecialite: any;
  showedValueVille: any;
  disabledbtn = false;
  rechercheWithPositionStatus = false
  p: any
  codePostalValue = 2092
  specialites: any;
  constructor(private router: Router, private iziToast: Ng2IzitoastService, private _sanitizer: DomSanitizer,
    private medecinService: MedecinService,
    private formBuilder: FormBuilder, private authService: AuthService) {

    this.form = this.formBuilder.group({
      medecin: ['', Validators.maxLength(500)],
      specialite: [null],
      ville: [null, Validators.maxLength(500)],
      codePostal: [''],
      rechercheWithPosition: [false]
    })
  }

  ngOnInit() {
    this.villes = villes[0].ville
    this.specialites = specialites
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.options = {
      multiple: false,
      maximumSelectionLength: 10
    }
    this.medicamentsData = [
      {
        id: specialites[0],
        text: specialites[0]
      }
    ];
    this.villeData = [
      {
        id: "Ariana",
        text: "Ariana"
      }
    ];
    for (var j = 1; j < specialites.length; j++) {
      this.medicamentsData.push(
        {
          id: specialites[j],
          text: specialites[j]
        }
      )
    }

    for (var j = 1; j < villes[0].ville.length; j++) {
      this.villeData.push(
        {
          id: villes[0].ville[j],
          text: villes[0].ville[j]
        }
      )
    }
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionMedecins) {
          this.router.navigate(['/']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      var medecinId = JSON.parse(secretaireString).medecinId;

      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionMedecins) {
          this.router.navigate(['/']);
          return false;
        }
      },
        err => {
          console.log(err);
          return false;
        });
    }
    this.medecinService.getMedecinScapedcount().subscribe(length => {
      this.lengthMedecin = length;
    },
      err => {
        return false;
      });
    //this.getMedecins(this.params);
    this.medecinService.getMedecinAccepted().subscribe((medecins: any[]) => {
      this.medecinService.getMedecinScapedBypage(this.pageNumber, 20).subscribe((medecinsss: any[]) => {
        this.mesMedecins = medecins.concat(medecinsss);
        this.staticMedecinscraped = medecinsss
        this.medecinss = this.mesMedecins.sort(() => Math.random() - Math.random()).slice(0, 10)
        this.suivreMedecin(this.pageNumber + 1)
      },
        err => {
          return false;
        });

    },
      err => {
        console.log(err);
        return false;
      });

    this.form.value.specialite = ""
    this.form.value.ville = ""

  }

  suivreMedecin(counter) {
    if (this.lengthMedecin === this.staticMedecinscraped.length) {
      return true;
    }
    this.medecinService.getMedecinScapedBypage(counter, 20).subscribe((medecinsss: any[]) => {
      this.mesMedecins.push(...medecinsss)
      this.staticMedecinscraped.push(...medecinsss)
      //this.mesMedecins.push(...medecinsss);
      counter++
      setTimeout(() => {    //<<<---    using ()=> syntax
        this.suivreMedecin(counter);
      }, 1000);
      return true;
    },
      err => {
        return false;
      });
  }
  recherche() {

    // if (!this.form.value.specialite) {
    //   this.form.value.specialite = "specialite"
    // }
    // if (!this.form.value.medecin) {
    //   this.form.value.medecin = "nom"
    // }
    // if (!this.form.value.ville) {
    //   this.form.value.ville = "ville"
    // }



    this.medecinss = []
    while (this.medecinss.length > 0) {
      this.medecinss.pop();
    }
    if (!this.rechercheWithPositionStatus) {
      if (this.form.value.specialite || this.form.value.medecin || this.form.value.ville) {
        this.medecinService.searchMedecinScrapped(this.form.value.medecin, this.form.value.specialite, this.form.value.ville).subscribe((data: any) => {
          this.medecinss = data;
        }, error => {
          console.error('Error fetching medecins', error);
        });
      }


      // // recherche with specialite
      // if (this.form.value.specialite != "specialite" && this.form.value.specialite != null && (this.form.value.medecin === "nom" || this.form.value.medecin == undefined || this.form.value.medecin == "") && (this.form.value.ville === "ville" || this.form.value.ville == undefined || this.form.value.ville == "" || !this.form.value.ville) && !this.selectedMedecinn) {
      //   this.medecinss = this.mesMedecins.filter(element => element.specialite == this.form.value.specialite)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     console.log(this.medecinss)
      //     let specialiteArray = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //     let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...seracharray)
      //     this.medecinss = this.removeDuplicates(this.medecinss)

      //   }
      //   return false;
      // }
      // // recherche with nothing
      // if ((this.form.value.specialite == null || !this.form.value.specialite || this.form.value.specialite == "specialite") && (this.form.value.medecin === "nom" || this.form.value.medecin == undefined || this.form.value.medecin == "") && (this.form.value.ville === "ville" || this.form.value.ville == undefined || this.form.value.ville == "" || !this.form.value.ville) && !this.selectedMedecinn) {
      //   let specialiteArray = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //   let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < 20)
      //   this.medecinss = seracharray
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   return false;
      // }
      // // recherche with medecin sans séléection
      // if ((this.form.value.specialite == "specialite" || !this.form.value.specialite || this.form.value.specialite == undefined || this.form.value.specialite == "") && (this.form.value.medecin != "nom" && this.form.value.medecin != undefined) && (this.form.value.ville === "ville" || this.form.value.ville == undefined || this.form.value.ville == "" || !this.form.value.ville) && !this.selectedMedecinn) {
      //   this.medecinss = this.mesMedecins.filter(element => element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let specialiteArray = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //     let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...seracharray)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //   }
      //   return false;
      // }

      // if ((this.form.value.specialite == "specialite" || !this.form.value.specialite || this.form.value.specialite == undefined || this.form.value.specialite == "") && (this.form.value.medecin != "nom" && this.form.value.medecin != undefined) && (this.form.value.ville === "ville" || this.form.value.ville == undefined || this.form.value.ville == "" || !this.form.value.ville) && this.selectedMedecinn) {
      //   let existMd = this.mesMedecins.find(element => element._id == this.selectedMedecinn._id)
      //   this.medecinss.push(existMd)
      //   let specialiteArray = this.mesMedecins.filter(element => element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) && (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase())))
      //   let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < Number(20 - this.medecinss.length))
      //   this.medecinss.push(...seracharray)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let specialiteArrayy = this.mesMedecins.filter(element => element.specialite.toLowerCase().indexOf(this.selectedMedecinn.specialite.toLowerCase()) > -1)
      //     let seracharrayy = specialiteArrayy.filter((specialiteArrayy, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...seracharrayy)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //     if (this.medecinss && this.medecinss.length < 20) {
      //       let specialiteArrayyy = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //       let seracharrayyy = specialiteArrayyy.filter((specialiteArrayyy, idx) => idx < Number(20 - this.medecinss.length))
      //       this.medecinss.push(...seracharrayyy)
      //       this.medecinss = this.removeDuplicates(this.medecinss)
      //     }
      //   }
      //   return false;
      // }

      // if (this.form.value.specialite != "specialite" && this.form.value.medecin != "nom" && (this.form.value.ville === "ville" || this.form.value.ville == undefined || this.form.value.ville == "" || !this.form.value.ville) && !this.selectedMedecinn) {
      //   let specialiteArray = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))))
      //   this.medecinss = specialiteArray.filter((specialiteArray, idx) => idx < 20)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let specialiteArrayy = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite))
      //     let seracharrayyyBefore = specialiteArrayy.filter((specialiteArrayy, idx) => idx < 20)
      //     this.medecinss.push(...seracharrayyyBefore)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //     if (this.medecinss && this.medecinss.length < 20) {
      //       let specialiteArrayylast = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))
      //       let seracharrayyyLast = specialiteArrayylast.filter((specialiteArrayylast, idx) => idx < 20)
      //       this.medecinss.push(...seracharrayyyLast)
      //       this.medecinss = this.removeDuplicates(this.medecinss)
      //       if (this.medecinss && this.medecinss.length < 20) {
      //         let specialiteArrayyyautre = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //         let seracharrayyyautre = specialiteArrayyyautre.filter((specialiteArrayyyautre, idx) => idx < Number(20 - this.medecinss.length))
      //         this.medecinss.push(...seracharrayyyautre)
      //         this.medecinss = this.removeDuplicates(this.medecinss)
      //       }
      //     }
      //   }
      //   return false;
      // }

      // // recherche with selected medecin and specialite

      // if (this.form.value.specialite != "specialite" && this.form.value.medecin != "nom" && (this.form.value.ville === "ville" || this.form.value.ville == undefined || this.form.value.ville == "" || !this.form.value.ville) && this.selectedMedecinn) {
      //   let existMd = this.mesMedecins.find(element => element._id == this.selectedMedecinn._id)
      //   this.medecinss.push(existMd)
      //   let specialiteArray = this.mesMedecins.filter(element => element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) && (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase())) && (element.specialite == this.form.value.specialite))
      //   let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < Number(20 - this.medecinss.length))
      //   this.medecinss.push(...seracharray)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let namOrNmaeSpecialiteArrayy = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) && element.specialite == this.form.value.specialite) || (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()) && element.specialite == this.form.value.specialite))
      //     let seracharraynameprenomspec = namOrNmaeSpecialiteArrayy.filter((namOrNmaeSpecialiteArrayy, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...seracharraynameprenomspec)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //     if (this.medecinss && this.medecinss.length < 20) {
      //       let nameprenomArray = this.mesMedecins.filter(element => element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase())))
      //       let serachNamePrenomarray = nameprenomArray.filter((nameprenomArray, idx) => idx < Number(20 - this.medecinss.length))
      //       this.medecinss.push(...seracharray)
      //       this.medecinss = this.removeDuplicates(this.medecinss)
      //       if (this.medecinss && this.medecinss.length < 20) {
      //         let specArraylist = this.mesMedecins.filter(element => element.specialite == this.form.value.specialite)
      //         let serachSpecarray = specArraylist.filter((specArraylist, idx) => idx < Number(20 - this.medecinss.length))
      //         this.medecinss.push(...serachSpecarray)
      //         this.medecinss = this.removeDuplicates(this.medecinss)
      //         if (this.medecinss && this.medecinss.length < 20) {
      //           let specialiteArrayyylast = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //           let seracharrayylast = specialiteArrayyylast.filter((specialiteArrayyylast, idx) => idx < Number(20 - this.medecinss.length))
      //           this.medecinss.push(...seracharrayylast)
      //           this.medecinss = this.removeDuplicates(this.medecinss)
      //         }
      //       }
      //     }
      //   }
      //   return false;
      // }
      // // recherche with not selected médecin, ville et spécialité

      // if (this.form.value.specialite != "specialite" && this.form.value.medecin != "nom" && this.form.value.ville != "ville" && !this.selectedMedecinn) {
      //   let specialiteArray = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite && (element.nom && element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom && element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())) && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1))))
      //   this.medecinss = specialiteArray.filter((specialiteArray, idx) => idx < 20)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let specialiteArrayyy = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1))))
      //     let seracharrayyy = specialiteArrayyy.filter((specialiteArrayyy, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...seracharrayyy)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //     if (this.medecinss && this.medecinss.length < 20) {
      //       let specialiteNomArrayyy = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite && (element.nom && element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom && element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))))
      //       let seracharrayyyseoncd = specialiteNomArrayyy.filter((specialiteNomArrayyy, idx) => idx < Number(20 - this.medecinss.length))
      //       this.medecinss.push(...seracharrayyyseoncd)
      //       this.medecinss = this.removeDuplicates(this.medecinss)
      //       if (this.medecinss && this.medecinss.length < 20) {
      //         let specialiteFilterArrayyy = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite))
      //         this.medecinss.push(...specialiteFilterArrayyy)
      //         this.medecinss = this.removeDuplicates(this.medecinss)
      //         if (this.medecinss && this.medecinss.length < 20) {
      //           let specArr = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér") && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //           let specArrfinal = specArr.filter((specArr, idx) => idx < Number(20 - this.medecinss.length))
      //           this.medecinss.push(...specArrfinal)
      //           this.medecinss = this.removeDuplicates(this.medecinss)
      //         }
      //       }

      //     }

      //   }
      //   return false;
      // }

      // // recherche with selected médecin & ville & spécialité
      // if (this.form.value.specialite != "specialite" && this.form.value.medecin != "nom" && this.form.value.ville != "ville" && this.selectedMedecinn) {
      //   let existMd = this.mesMedecins.find(element => element._id == this.selectedMedecinn._id)
      //   this.medecinss.push(existMd)
      //   let specialiteArray = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))) && (element.specialite === this.form.value.specialite) && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //   let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < Number(20 - this.medecinss.length))
      //   this.medecinss.push(...seracharray)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let firstsearch = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))) && (element.specialite === this.form.value.specialite))
      //     let seracharrayfirstsearch = firstsearch.filter((firstsearch, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...seracharrayfirstsearch)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //     if (this.medecinss && this.medecinss.length < 20) {
      //       let seocndsearch = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))) && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //       let seracharrayseocndsearch = seocndsearch.filter((seocndsearch, idx) => idx < Number(20 - this.medecinss.length))
      //       this.medecinss.push(...seracharrayseocndsearch)
      //       this.medecinss = this.removeDuplicates(this.medecinss)
      //       if (this.medecinss && this.medecinss.length < 20) {
      //         let specArr = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))))
      //         let specArrfinal = specArr.filter((specArr, idx) => idx < Number(20 - this.medecinss.length))
      //         this.medecinss.push(...specArrfinal)
      //         this.medecinss = this.removeDuplicates(this.medecinss)
      //         if (this.medecinss && this.medecinss.length < 20) {
      //           let specArrr = this.mesMedecins.filter(element => element.specialite == this.form.value.specialite && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //           let specArrfinall = specArrr.filter((specArrr, idx) => idx < Number(20 - this.medecinss.length))
      //           this.medecinss.push(...specArrfinall)
      //           this.medecinss = this.removeDuplicates(this.medecinss)
      //           if (this.medecinss && this.medecinss.length < 20) {
      //             let specArrrr = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér") && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //             let specArrfinalll = specArrrr.filter((specArrrr, idx) => idx < Number(20 - this.medecinss.length))
      //             this.medecinss.push(...specArrfinalll)
      //             this.medecinss = this.removeDuplicates(this.medecinss)
      //           }
      //         }
      //       }
      //     }

      //   }
      //   return false;
      // }
      // // recherche with not selected medecin , by nom and ville

      // if ((this.form.value.specialite === "specialite" || !this.form.value.specialite || this.form.value.specialite == "") && this.form.value.medecin != "nom" && this.form.value.ville != "ville" && !this.selectedMedecinn) {
      //   let specialiteArray = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))) && ((((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))))
      //   let seracharray = specialiteArray.filter((specialiteArray, idx) => idx < Number(20))
      //   this.medecinss.push(...seracharray)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let myspecArrrr = this.mesMedecins.filter(element => (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))))
      //     let myspecArrfinalll = myspecArrrr.filter((myspecArrrr, idx) => idx < Number(20 - this.medecinss.length))
      //     this.medecinss.push(...myspecArrfinalll)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //     if (this.medecinss && this.medecinss.length < 20) {
      //       let specArrrr = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér") && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //       let specArrfinalll = specArrrr.filter((specArrrr, idx) => idx < Number(20 - this.medecinss.length))
      //       this.medecinss.push(...specArrfinalll)
      //       this.medecinss = this.removeDuplicates(this.medecinss)
      //       if (this.medecinss && this.medecinss.length < 20) {
      //         let specA = this.mesMedecins.filter(element => (element.nom.toLowerCase() == this.form.value.medecin.toLowerCase() || (element.prenom && element.prenom.toLowerCase() == this.form.value.medecin.toLowerCase())))
      //         let nomandPrenomFinal = specA.filter((specA, idx) => idx < Number(20 - this.medecinss.length))
      //         this.medecinss.push(...nomandPrenomFinal)
      //         this.medecinss = this.removeDuplicates(this.medecinss)
      //       }
      //     }
      //   }

      //   return false;
      // }
      // // recherche with ville
      // if ((this.form.value.specialite === "specialite" || !this.form.value.specialite || this.form.value.specialite == "") && this.form.value.medecin === "nom" && this.form.value.ville != "ville" && !this.selectedMedecinn) {
      //   let specArrrr = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér") && ((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1) || (element.adresseCabinet && element.adresseCabinet.ville && element.adresseCabinet.ville.toLowerCase().indexOf(this.form.value.ville.toLowerCase()) > -1)))
      //   let specArrfinalll = specArrrr.filter((specArrrr, idx) => idx < Number(20))
      //   this.medecinss.push(...specArrfinalll)
      //   this.medecinss = this.removeDuplicates(this.medecinss)
      //   if (this.medecinss && this.medecinss.length < 20) {
      //     let villeArrrr = this.mesMedecins.filter(element => element.specialite.toLowerCase().includes("génér"))
      //     let villeFinal = villeArrrr.filter((villeArrrr, idx) => idx < Number(20))
      //     this.medecinss.push(...villeFinal)
      //     this.medecinss = this.removeDuplicates(this.medecinss)
      //   }
      //   return false;
      // }
    }
    if (this.rechercheWithPositionStatus) {
      // recherche with no selection

      if ((this.form.value.specialite === "specialite" || !this.form.value.specialite || this.form.value.specialite == "") && this.form.value.medecin === "nom" && this.form.value.ville != "ville" && !this.selectedMedecinn) {

        if (!this.codePostalValue || this.codePostalValue === null || this.codePostalValue === undefined) {
          this.codePostalValue = 2092
        }
        var codes = [Number(this.codePostalValue)]
        for (var j = 1; j < 8; j++) {
          codes.push(Number(this.codePostalValue - j))
          codes.push(Number(this.codePostalValue) + j)
          if (j == 7) {
            let initaltArr = []
            for (var cc = 0; cc < codes.length; cc++) {
              let mylistemed = this.mesMedecins.filter(element => (element.specialite.toLowerCase().includes("génér")) && (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[cc]) > -1) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[cc]) > -1))))

              initaltArr.push(...mylistemed)
              if (cc === Number(codes.length - 1)) {
                initaltArr = this.removeDuplicates(initaltArr)
                this.medecinss = initaltArr.filter((initaltArr, idx) => idx < Number(20))
              }
            }
            return false;
          }
        }
        return false;
      }
      // recherche with nom
      if ((this.form.value.specialite === "specialite" || !this.form.value.specialite || this.form.value.specialite == "") && this.form.value.medecin != "nom" && this.form.value.medecin != "" && this.form.value.ville != "ville" && !this.selectedMedecinn) {
        if (!this.codePostalValue || this.codePostalValue == null || this.codePostalValue == undefined) {
          this.codePostalValue = 2092
        }
        var codes = [Number(this.codePostalValue)]
        for (var j = 1; j < 8; j++) {
          codes.push(Number(this.codePostalValue - j))
          codes.push(Number(this.codePostalValue) + j)
          if (j == 7) {
            let initaltArr = []
            let mylistemed = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && ((element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))))))
            initaltArr.push(...mylistemed)
            let mylistemedd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[1]) > -1 && (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[1]) > -1 && ((element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))))))
            initaltArr.push(...mylistemedd)
            initaltArr = this.removeDuplicates(initaltArr)
            this.medecinss = initaltArr.filter((initaltArr, idx) => idx < Number(20))
            if (this.medecinss && this.medecinss.length < 20) {
              let mylistemeddd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && element.specialite.toLowerCase().includes("génér")) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && element.specialite.toLowerCase().includes("génér")))))
              let mylistemedddd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[1]) > -1 && element.specialite.toLowerCase().includes("génér")) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[1]) > -1 && element.specialite.toLowerCase().includes("génér")))))
              this.medecinss.push(...mylistemeddd)
              this.medecinss.push(...mylistemedddd)
              this.medecinss = this.removeDuplicates(this.medecinss)
              this.medecinss = this.medecinss.filter((initaltArr, idx) => idx < Number(20))
              if (this.medecinss && this.medecinss.length < 20) {
                let nameprenomArrayNomPrenom = this.mesMedecins.filter(element => element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || (element.prenom && element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))
                this.medecinss.push(...nameprenomArrayNomPrenom)
                this.medecinss = this.removeDuplicates(this.medecinss)
                this.medecinss = this.medecinss.filter((initaltArr, idx) => idx < Number(20))
              }
            }

            return false;
          }
        }
        return false;
      }
      // recherche with nom and specialite
      if ((this.form.value.specialite != "specialite" && this.form.value.specialite != "") && this.form.value.medecin != "nom" && this.form.value.medecin != "" && this.form.value.ville != "ville" && !this.selectedMedecinn) {

        if (!this.codePostalValue || this.codePostalValue == null || this.codePostalValue == undefined) {
          this.codePostalValue = 2092
        }
        var codes = [Number(this.codePostalValue)]
        for (var j = 1; j < 8; j++) {
          codes.push(Number(this.codePostalValue - j))
          codes.push(Number(this.codePostalValue) + j)
          if (j == 7) {
            let initaltArr = []
            let mylistemed = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite && ((element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))))))
            initaltArr.push(...mylistemed)
            let mylistemedd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite && ((element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase())))))))
            initaltArr.push(...mylistemedd)
            initaltArr = this.removeDuplicates(initaltArr)
            this.medecinss = initaltArr.filter((initaltArr, idx) => idx < Number(20))
            if (this.medecinss && this.medecinss.length < 20) {
              let initaltArrr = []
              let mylistemedd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite))))
              initaltArrr.push(...mylistemedd)
              let mylistemeddd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite))))
              initaltArrr.push(...mylistemeddd)
              initaltArrr = this.removeDuplicates(initaltArrr)
              var newrr = initaltArrr.filter((initaltArrr, idx) => idx < Number(20))
              this.medecinss.push(...newrr)
              this.medecinss = this.removeDuplicates(this.medecinss)
              if (this.medecinss && this.medecinss.length < 20) {
                let specialiteArray = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.form.value.medecin.toLowerCase()) || element.prenom.toLowerCase().includes(this.form.value.medecin.toLowerCase()))))
                let resul = specialiteArray.filter((specialiteArray, idx) => idx < 20)
                this.medecinss.push(...resul)
                this.medecinss = this.removeDuplicates(this.medecinss)
                if (this.medecinss && this.medecinss.length < 20) {
                  let specialiteArrayy = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite))
                  let resull = specialiteArrayy.filter((specialiteArrayy, idx) => idx < 20)
                  this.medecinss.push(...resull)
                  this.medecinss = this.removeDuplicates(this.medecinss)
                }
              }

            }
          }
        }
        return false;
      }

      // recherche with nom and specialite ans selected médecin
      if ((this.form.value.specialite != "specialite" && this.form.value.specialite != "") && this.form.value.medecin != "nom" && this.form.value.medecin != "" && this.form.value.ville != "ville" && this.selectedMedecinn) {
        if (!this.codePostalValue || this.codePostalValue == null || this.codePostalValue == undefined) {
          this.codePostalValue = 2092
        }
        var codes = [Number(this.codePostalValue)]
        for (var j = 1; j < 8; j++) {
          codes.push(Number(this.codePostalValue - j))
          codes.push(Number(this.codePostalValue) + j)
          if (j == 7) {
            let myMed = this.mesMedecins.find(element => element._id == this.selectedMedecinn._id)
            let initaltArr = []
            initaltArr.push(myMed)
            let mylistemed = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite && ((element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase())))))))
            initaltArr.push(...mylistemed)
            let mylistemedd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite && ((element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase())))))))
            initaltArr.push(...mylistemedd)
            initaltArr = this.removeDuplicates(initaltArr)
            this.medecinss = initaltArr.filter((initaltArr, idx) => idx < Number(20))
            if (this.medecinss && this.medecinss.length < 20) {
              let initaltArrr = []
              let mylistemedd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && element.specialite == this.form.value.specialite))))
              initaltArrr.push(...mylistemedd)
              let mylistemeddd = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[1]) > -1 && element.specialite == this.form.value.specialite))))
              initaltArrr.push(...mylistemeddd)
              initaltArrr = this.removeDuplicates(initaltArrr)
              var newrr = initaltArrr.filter((initaltArrr, idx) => idx < Number(20))
              this.medecinss.push(...newrr)
              this.medecinss = this.removeDuplicates(this.medecinss)
              if (this.medecinss && this.medecinss.length < 20) {
                let specialiteArray = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite && (element.nom.toLowerCase().includes(this.selectedMedecinn.nom.toLowerCase()) || element.prenom.toLowerCase().includes(this.selectedMedecinn.prenom.toLowerCase()))))
                let resul = specialiteArray.filter((specialiteArray, idx) => idx < 20)
                this.medecinss.push(...resul)
                this.medecinss = this.removeDuplicates(this.medecinss)
                if (this.medecinss && this.medecinss.length < 20) {
                  let specialiteArrayy = this.mesMedecins.filter(element => (element.specialite == this.form.value.specialite))
                  let resull = specialiteArrayy.filter((specialiteArrayy, idx) => idx < 20)
                  this.medecinss.push(...resull)
                  this.medecinss = this.removeDuplicates(this.medecinss)
                  if (this.medecinss && this.medecinss.length < 20) {
                    let specialiteArrayylastt = this.mesMedecins.filter(element => (((element.adresseCabinet && element.adresseCabinet.adresse && element.adresseCabinet.adresse.toLowerCase().indexOf(codes[0]) > -1 && element.specialite.toLowerCase().includes("génér")) || (element.adresseCabinet && element.adresseCabinet.codePostal && element.adresseCabinet.codePostal.indexOf(codes[0]) > -1 && element.specialite.toLowerCase().includes("génér")))))
                    let resulllast = specialiteArrayylastt.filter((specialiteArrayylastt, idx) => idx < 20)
                    this.medecinss.push(...resulllast)
                    this.medecinss = this.removeDuplicates(this.medecinss)
                  }
                }
              }

            }
          }
        }
        return false;
      }
    }
    return false;
  }
  myCallback(event) {
    this.selectedMedecinn = undefined
    if (event._id) {
      this.selectedMedecinn = event
      if (!event.prenom) {
        this.nom = event.nom + ' ' + event.specialite
      }
      if (event.prenom) {
        this.nom = event.nom + ' ' + event.prenom + ' ' + event.specialite
      }
    }
  }
  myCallbackSpec(event) {
    //console.log(this.form.value)
    if (event.id) {
      this.showedValueSpecialite = event.text
    }
    setTimeout(() => {
      this.form.controls['specialite'].setValue(this.showedValueSpecialite);
      this.showedValueSpecialite = event.text
      return true;
    }, 200)
  }
  myCallbackVille(event) {
    //console.log(this.form.value)
    this.rechercheWithPositionStatus = false
    if (event.id) {
      this.showedValueVille = event.text
    }
    setTimeout(() => {
      this.form.controls['ville'].setValue(this.showedValueVille);
      this.showedValueVille = event.text
      return true;
    }, 200)
  }
  autocompleListFormatterPrenom = (data: any): SafeHtml => {
    if (data.nom != undefined && data.prenom != undefined) {
      var html = `<span>${data.nom} ${data.prenom} ${data.specialite}</span>`;
    }
    if (data.prenom == undefined) {
      var html = `<span>${data.nom} ${data.specialite}</span>`;
    }
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  autocompleListFormatterSpecialite = (data: any): SafeHtml => {
    var html = `<span>${data.text}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  autocompleListFormatterVille = (data: any): SafeHtml => {
    var html = `<span>${data.text}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  shuffle(list) {
    return list.reduce((p, n) => {
      const size = p.length;
      const index = Math.trunc(Math.random() * (size - 1));
      p.splice(index, 0, n);
      return p;
    }, []);
  };

  removeDuplicates(array) {
    let uniq = {};
    return array.filter(obj => !uniq[obj._id] && (uniq[obj._id] = true))
  }

  onKey($event) {
    this.selectedMedecinn = undefined
  }
  // -------------------------
  toggleFavorite(medecin: any) {
    medecin.favorite = !medecin.favorite;
  }

  // rechercherMedecins(): void {
  //   let queryParams = {};
  //   if (this.nomMedecin) {
  //     queryParams['prenom_nom'] = this.nomMedecin;
  //   }
  //   if (this.specialiteMedecin) {
  //     queryParams['specialite'] = this.specialiteMedecin;
  //   }
  //   if (this.villeMedecin) {
  //     queryParams['localisation'] = this.villeMedecin;
  //   }



  //   // Vérifier si l'objet queryParams est vide
  //   if (Object.keys(queryParams).length === 0) {
  //     return;
  //   }

  //   // Naviguer vers le composant de résultat avec les paramètres dans l'URL
  //   const url = '/recherche-medecins?' + new URLSearchParams(queryParams).toString();
  //   window.open(url, '_blank');
  //   //this.router.navigate(['/medecin'], { queryParams: queryParams });
  // }

  chat() {
    this.iziToast.show({
      message: "Le service de messagerie est actuellement indisponible.",
      messageColor: '#800f2f',
      titleColor: '#800f2f',
      progressBarColor: '#c9184a',
      imageWidth: 45,
      position: 'topRight',
      timeout: 5000,
      backgroundColor: '#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay: true,
      overlayClose: true,
    });
  }
}
