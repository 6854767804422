<div class="sidebar">
  <div class="sidebar-links-container">
    <ul class="sidebar-links">
      <!-- Interrogatoire Parent -->
      <li class="link-item">
        <a 
          (click)="navigateToInterrogatoire()"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.active]="isActiveLink('interrogatoire')">
          <i class="fa-solid fa-file-circle-question mr-2"></i>
          Interrogatoire
        </a>
        <ul class="sub-links" *ngIf="isActiveLink('interrogatoire')">
          <li>
            <a 
              [routerLink]="['/medecin/examen/interrogatoire/antecedents-medicaux']"
              [routerLinkActive]="['active']" 
              [routerLinkActiveOptions]="{ exact: false }">Antécédents Médicaux</a>
          </li>
          <li>
            <a 
              [routerLink]="['/medecin/examen/interrogatoire/interrogatoire-systematique']"
              [routerLinkActive]="['active']" 
              [routerLinkActiveOptions]="{ exact: false }">Interrogatoire Systématique</a>
          </li>
        </ul>      
      </li>
  
      <!-- Examen Parent -->
      <li class="link-item">
        <a 
          [routerLink]="['/medecin/examen/exam']"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.active]="isActiveLink('exam')">
          <i class="fa-solid fa-stethoscope mr-2"></i>
          Examen
        </a>      
      </li>
  
      <!-- Conduite à tenir Parent -->
      <li class="link-item">
        <a 
          [routerLink]="['/medecin/examen/conclusion']"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.active]="isActiveLink('conclusion')">
          <i class="fa-solid fa-file-waveform mr-2"></i>
          Conclusion
        </a>      
      </li>
  
      <!-- Conduite à tenir Parent -->
      <li class="link-item">
        <a 
          [routerLink]="['/medecin/examen/conduite-tenir']"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.active]="isActiveLink('conduite-tenir')">
          <i class="fa-solid fa-briefcase-medical mr-2"></i>
          Conduite à tenir
        </a>      
      </li>
  
      <!-- Documents Parent -->
      <li class="link-item">
        <a 
          [routerLink]="['/medecin/examen/documents']"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.active]="isActiveLink('documents')">
          <i class="fa-solid fa-file-medical mr-2"></i>
          Documents
        </a>      
      </li>
    </ul>
  </div>

  <div class="suivi-consultation">
    <hr class="sidebar-separator">
    <div class="buttons">
      <button class="btn btn-custom toggle-btn" title="Historiques des consultations" (click)="historiqueConsultationModal()">
        <i class="fa-solid fa-clock-rotate-left"></i>
      </button>
      <button class="btn btn-custom toggle-btn" title="Suivi codage de consultation" (click)="toggleOffCanvas()">
        <i class="fa-solid fa-notes-medical"></i>
      </button>
    </div>
  </div>

</div>

<div class="offcanvas-overlay" [ngClass]="{'show': isOpen}" (click)="close()"></div>

<div class="offcanvas" [ngClass]="{'show': isOpen}">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">Suivi codage de consultation</h5>
    <a class="exit" aria-label="Close" (click)="close()">
      <i class="fa-regular fa-circle-xmark"></i>
    </a>
  </div>
  <hr class="offcanvas-seperator">
  <div class="offcanvas-body">
    <!-- <p>This is the off-canvas content.</p> -->
  </div>
</div>
