import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conclusions',
  templateUrl: './conclusions.component.html',
  styleUrls: ['./conclusions.component.css']
})
export class ConclusionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
