import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anteced-med',
  templateUrl: './anteced-med.component.html',
  styleUrls: ['./anteced-med.component.css']
})
export class AntecedMedComponent implements OnInit {

  selectedPerso: any[] = [];
  selectedFamil: any[] = [];
  selectedTraitements: any[] = [];
  selectedChirurgies: any[] = [];
  selectedTabagism: any[] = [];
  selectedConsommation: any[] = [];
  selectedActivities: any[] = [];
  selectedHabitudes: any[] = [];

  descriptions = {
    antecedentsMedicaux: {
      antecedentsPersonnels: {
          conditions: [{ 
              type: String,
              description: { type: String }
          }]
      },
      antecedentsFamiliaux: {
          conditions: [{ 
              type: String,
              description: { type: String }
          }]
      }
  },
    antecedentsChirurgicaux: {
      proceduresDescription: ''
    },
    traitementsEnCours: {
      medicamentsDescription: '' 
    },
    modeDeVie: {
      tabagismeDescription: '',
      consommationAlcoolDescription: '',
      activitePhysiqueDescription: '',
      habitudesAlimentairesDescription: ''
    },
    interrogatoireSystematique: {
      general: {
        fievreDescription: '',
        pertePoidsDescription: ''
      },
      cardiovasculaire: {
        douleurThoraciqueDescription: '',
        palpitationsDescription: ''
      }
    }
    // Add other sections as needed
  };

  perso = [
    'Hypertension',
    'Diabète',
    'Asthme',
    'Maladies Cardiaques',
    'Troubles Thyroïdiens',
    'Allergies',
  ];
  
  famil = [
    'Maladies Cardiovasculaires',
    'Cancer',
    'Diabète',
    'Troubles Génétiques',
  ];
  
  traitements = [
    'Antibiotiques',
    'Antalgiques',
    'Antihypertenseurs',
    'Insuline',
  ];
  
  chirurgies = [
    'Appendicectomie',
    'Cure de Hernie',
    'Césarienne',
  ];
  
  tabagism = [
    'Jamais',
    'Ancien Fumeur',
    'Fumeur Actuel',
  ];
  
  consommation = [
    'Jamais',
    'Occasionnelle',
    'Régulière',
  ];
  
  activities = [
    'Sédentaire',
    'Modérée',
    'Active',
  ];
  
  habitudes = [
    'Végétarien',
    'Non-Végétarien',
    'Végan',
  ];


  constructor() { }

  ngOnInit(): void {
  }
  
}
