import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reusable-ngselect',
  templateUrl: './reusable-ngselect.component.html',
  styleUrls: ['./reusable-ngselect.component.css']
})
export class ReusableNgselectComponent implements OnInit {
  @Input() items: string[] = [];
  @Input() selectedItems: string | string[] = [];
  @Input() placeholder: string = 'Sélectionner les options';
  @Input() multiple: boolean = true;
  @Input() ngSelectStyle: any = {};
  @Input() selectedItemsStyle: any = {};
  @Input() selectContainer: any = {};
  @Input() label: string;
  
  @Output() selectedItemsChange = new EventEmitter<string | string[]>();

  constructor() { }

  ngOnInit(): void {
  }

  get selectedItemObjects() {
    return this.selectedItems;
  }

  onSelectionChange(selectedItems: any[]) {
    if (!this.multiple) {
      this.selectedItems = selectedItems.length > 0 ? [selectedItems[0]] : [];
    } else {
      this.selectedItems = selectedItems;
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }


  removeSelectedItem(index: number) {
    if (this.multiple && Array.isArray(this.selectedItems)) {
      this.selectedItems.splice(index, 1);
      this.selectedItemsChange.emit(this.selectedItems);
    }
  }

  clearAll() {
    this.selectedItems = this.multiple ? [] : '';
    this.selectedItemsChange.emit(this.selectedItems);
  }

}
