import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';


@Injectable({
  providedIn: 'root' // This ensures the guard is available application-wide
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {

  }

  canActivate() {
    if (localStorage.getItem('medecin') || localStorage.getItem('secretaire')) {
      return true;
    } else {
      this.router.navigate(['/medecin/login']);
      return false;
    }
  }
}
//to protect routers
